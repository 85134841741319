
import { UserInfo } from "@/components/choose-person/Index.vue";
import { Toast } from "vant";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

interface FormData {
  approval_way_type_name: string;
  approval_way_type: number;
  user_num: number;
  user_info: UserInfo[];
  power_approval_type: number;
}

export default defineComponent({
  name: "SelectApprove",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    info: {
      type: Object as PropType<FormData>,
      required: true
    }
  },
  emits: ["update:show", "submit"],
  setup(props, { emit }) {
    const { show, info: formData } = toRefs(props);
    const choosePersonShow = ref(false);
    const popupShow = computed({
      get() {
        return show.value;
      },
      set(value) {
        emit("update:show", value);
      }
    });
    function select() {
      choosePersonShow.value = true;
    }
    const confirm = (data: Dictionary<UserInfo[]>) => {
      formData.value.user_info = Object.values(data).flat();
    };
    const submit = async () => {
      if (formData.value.user_info.length < formData.value.approval_way_type) {
        Toast.fail(`至少选择${formData.value.approval_way_type}人`);
        return;
      }
      const ids = formData.value.user_info.map(item => item.id).join(",");
      popupShow.value = false;
      emit("submit", ids);
    };
    return {
      submit,
      select,
      choosePersonShow,
      confirm,
      popupShow
    };
  }
});
