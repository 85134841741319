
import { Picker, PickerType, UfUploadFileType, usePicker } from "@/composables";
import { defineComponent, inject, PropType, ref, toRefs, watchEffect } from "vue";

interface Certificate {
  id: number;
  certificateName: string;
  certificateTypePicker: Picker<"Single">;
  certificateFile: UfUploadFileType[];
}

export default defineComponent({
  name: "UfNvq",
  props: {
    options: {
      type: Object as PropType<Approval.Nvq>,
      default: {},
      required: true
    },
    default: {
      type: Array as PropType<Approval.FileRes[]>,
      default: []
    },
    modelValue: {
      type: String as PropType<string>,
      default: ""
    },
    maxlength: {
      type: Number,
      default: 100
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { default: defaultList } = toRefs(props);
    const disabled = inject("disabled", false);
    const certificates = ref<Certificate[]>([]);
    const removeCertificate = (index: number) => {
      certificates.value.splice(index, 1);
    };
    const addCertificate = (init?: Approval.FileRes) => {
      const initializeFile = (item: Approval.FileRes): any => {
        return {
          content: "",
          message: "上传成功",
          status: "done",
          url: item.photo_file,
          progress: 100,
          res: {
            file_name: item.file_name,
            file_size: item.file_size,
            file_type: item.photo_type,
            id: item.id,
            img_url: item.photo_file
          }
        };
      };
      const { picker } = usePicker(PickerType.Single.CERTIFICATE_TYPE, {
        defaultValue: init?.key,
        defaultLabel: init?.file_name
      });
      certificates.value.push({
        id: new Date().getTime(),
        certificateName: "",
        certificateTypePicker: picker,
        certificateFile: init ? [initializeFile(init)] : []
      });
    };
    if (defaultList.value.length) {
      defaultList.value.forEach(item => {
        addCertificate(item);
      });
    } else {
      if (disabled) return;
      addCertificate();
    }
    watchEffect(() => {
      const certificate = certificates.value
        .filter(certificate => certificate.certificateFile.length !== 0)
        .map(certificate => {
          const arr = [];
          if (certificate.certificateTypePicker.value) {
            arr.push(certificate.certificateTypePicker.value);
          }
          if (certificate.certificateFile[0].res.id) {
            arr.push(certificate.certificateFile[0].res.id);
          }
          if (certificate.certificateName) {
            arr.push(certificate.certificateName);
          }
          return arr;
        })
        .join(";");
      emit("update:modelValue", certificate);
    });
    const fieldClick = (item: Certificate) => {
      if (disabled) return;
      item.certificateTypePicker.showPicker = true;
    };
    return {
      disabled,
      fieldClick,
      addCertificate,
      certificates,
      removeCertificate
    };
  }
});
