
import { computed, defineComponent, PropType, ref, toRefs } from "vue";
import { ImagePreview } from "vant";
export default defineComponent({
  name: "UfSign",
  props: {
    options: {
      type: Object as PropType<Approval.Assign>,
      default: {},
      required: true
    },
    modelValue: {
      type: String
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { options } = toRefs(props);
    const src = ref(options.value.pic_arr?.[0]?.photo_file || "");
    const disabled = computed(() => options.value.power == 1);
    const popupShow = ref(false);
    const onClick = () => {
      if (disabled.value) {
        if (src.value) ImagePreview([src.value]);
        return;
      }
      popupShow.value = true;
    };
    const onConfirm = (data: any) => {
      src.value = data.img_url;
      emit("update:modelValue", data.id);
    };
    return {
      onClick,
      disabled,
      ImagePreview,
      popupShow,
      onConfirm,
      src
    };
  }
});
