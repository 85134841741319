import { api } from "@/utils/axios";

interface Authentication {
  type: "1" | "3" | "7";
  name: string;
  mobile: string;
  email?: string;
  gender?: string;
  positive?: string;
  back?: string;
  id_card?: string;
  start_time?: string;
  end_time?: string;
}

export interface StaffRequestBody extends Authentication {
  department_name?: string;
  duty_name?: string;
  subgroup_name?: string;
  certificate?: any[];
}

export interface StoresRequestBody extends Authentication {
  stores_name: string;
  duty_name?: string;
  stores_type?: string;
  stores_sub?: string;
  license?: string;
  social_credit?: string;
  license_start_time?: string;
  license_end_time?: string;
  place_name?: string;
  owner_name?: string;
  contact_phone?: string;
}

export interface FactoryRequestBody extends Authentication {
  factory_name: string;
  certificate?: string;
  license?: string;
  social_credit?: string;
  license_start_time?: string;
  license_end_time?: string;
  province?: string;
  city?: string;
  county?: string;
  address?: string;
  contact_name?: string;
  contact_phone?: string;
}

export interface CancelApplyBody {
  user_id?: string;
  state?: "3" | "4"; //3拒绝 4取消
  desc?: string;
}

export const userService = {
  getUserInfo() {
    return api.post<ResponseData>("/User/get_user_info");
  },
  getCertificateType() {
    return api.post<ResponseData<{ id: string; name: string }[]>>("/User/get_certificate_type");
  },
  authentication(body: StaffRequestBody | StoresRequestBody | FactoryRequestBody) {
    return api.post<ResponseData>("/User/user_authentication", body);
  },
  authenticationDetail() {
    return api.post<ResponseData>("/User/authentication_detail");
  },
  cancelApply(body?: CancelApplyBody) {
    return api.post<ResponseData>("/User/cancel_apply", body);
  },
  userLists(body: any) {
    return api.post<ResponseData>("/User/user_lists", body);
  },
  userInfo(body: any) {
    return api.post<ResponseData>("/User/user_info", body);
  }
};
