import { mydbService } from "@/service";
import Compressor from "compressorjs";
export interface UfUploadFileType {
  content: string;
  file: File;
  message: string;
  status: string;
  url: string;
  progress: number;
  res: {
    file_name: string;
    file_size: number;
    file_type: string;
    id: string;
    img_url: string;
    photo_file?: string;
    photo_type?: string;
  };
}

export function useUpload(type?: string, cb?: (data: any) => void) {
  const upload = async (file: UfUploadFileType) => {
    if (type === "idcard") {
      const { data } = await mydbService.idcardDistinguish(file.file);
      cb?.(data);
    }
    file.status = "uploading";
    file.message = "上传中...";
    file.progress = 0;
    file.res = {
      file_name: "",
      file_size: 1,
      file_type: "",
      id: "",
      img_url: ""
    };

    try {
      console.log("yuanwenjian", file.file);
      const newFile = await new Promise<File>((resolve, reject) => {
        new Compressor(file.file, {
          quality: 0.6,
          success(result: any) {
            console.log("压缩成了", result);
            resolve(result);
          },
          error() {
            resolve(file.file);
          }
        });
      });
      file.file = newFile;
      const { data } = await mydbService.saveImg(
        {
          my_files: file.file
        },
        progress => {
          file.progress = progress;
        }
      );
      file.status = "done";
      file.message = "上传成功";
      file.url = data.data[0].img_url;
      file.res = data.data[0];
    } catch (error) {
      file.status = "failed";
      file.message = "上传失败";
    }
  };

  const removeUploaded = (origin: UfUploadFileType[], index: number) => {
    origin.splice(index, 1);
  };

  return {
    upload,
    removeUploaded
  };
}
