
import { StoresInfo } from "@/components/Stores.vue";
import { computed, defineComponent, PropType, ref, toRefs, inject, onBeforeUpdate } from "vue";

export default defineComponent({
  name: "UfCheckStores",
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Object as PropType<Approval.Stores>,
      default: {},
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { options, modelValue } = toRefs(props);
    const isEventDetail = inject("isEventDetail");
    const storesShow = ref(false);

    const disabled = computed(() => options.value.power == 1);
    const fieldValue = ref("");
    if (options.value.stores_arr) {
      options.value.stores_arr.forEach(item => {
        (item as any).showPopover = false;
      });
      const names = options.value.stores_arr.map(item => item.stores_name);
      fieldValue.value = names.length > 1 ? `已选${names.length}个机构` : names.join(",");
    }
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });
    const showCheckedList = ref(false);

    const onClick = () => {
      if (disabled.value) {
        if (options.value.stores_arr) {
          showCheckedList.value = true;
        }
        return;
      }
      storesShow.value = true;
    };
    const onConfirm = (values: StoresInfo[]) => {
      const names = values.map(item => item.stores_name);
      fieldValue.value = names.length > 1 ? `已选${names.length}个机构` : names.join(",");
      value.value = values.map(item => item.id).join(",");
    };
    // 已选
    const checkedPlacePopover = ref<boolean[]>([]);
    const checkedPlaceRef = ref<any[]>([]);
    onBeforeUpdate(() => {
      checkedPlaceRef.value = [];
    });

    const addCheckedPlaceRef = async (el: Element, index: number) => {
      checkedPlaceRef.value[index] = el;
    };

    const opened = () => {
      checkedPlaceRef.value.forEach((el, index) => {
        checkedPlacePopover.value[index] = el.getBoundingClientRect().right > window.innerWidth - 30;
      });
    };
    return {
      onConfirm,
      storesShow,
      value,
      disabled,
      onClick,
      fieldValue,
      isEventDetail,
      showCheckedList,
      checkedPlaceRef,
      checkedPlacePopover,
      addCheckedPlaceRef,
      opened
    };
  }
});
