<template>
  <van-popup v-bind="$attrs">
    <van-cell>
      <div class="popup--top__button popup--top__button--mr15" @click="$emit('cancel')">
        取消
      </div>
      <div class="popup--top__button" @click="$emit('clear')">清空</div>
      <div class="popup--top__button popup--top__button--primary" style="float: right" @click="$emit('ok')">
        确定
      </div>
    </van-cell>

    <slot></slot>
  </van-popup>
</template>

<script>
export default {
  name: "UfPopup"
};
</script>

<style scoped lang="less">
.popup--top__button {
  display: inline-block;
  font-size: 15px;
  color: #2d333e;
  &--primary {
    color: #0065ff;
  }
  &--mr15 {
    margin-right: 15px;
  }
}
</style>
