
import { UserInfo } from "@/components/choose-person/Index.vue";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

export default defineComponent({
  name: "UfUser",
  props: {
    modelValue: {
      type: String,
      required: true
    },
    options: {
      type: Object as PropType<Approval.User>,
      default: {},
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { options, modelValue } = toRefs(props);
    const choosePersonShow = ref(false);

    const disabled = computed(() => options.value.power == 1);
    const fieldValue = ref("");
    if (options.value.user_info) {
      fieldValue.value = options.value.user_info.map(item => item.name).join(",");
    }
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });
    const onClick = () => {
      if (disabled.value) return true;
      choosePersonShow.value = true;
    };
    const onConfirm = (data: Dictionary<UserInfo[]>) => {
      const values = Object.values(data).flat();
      fieldValue.value = values.map(item => item.name).join(",");
      value.value = values.map(item => item.id).join(",");
    };
    return {
      onConfirm,
      choosePersonShow,
      value,
      disabled,
      onClick,
      fieldValue
    };
  }
});
