import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")
  const _component_uf_daterange_picker = _resolveComponent("uf-daterange-picker")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_uf_form_item, {
      title: _ctx.options.title,
      required: _ctx.options.is_checked == '1',
      readonly: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, _mergeProps({
          modelValue: _ctx.showVal,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showVal = $event)),
          onClick: _ctx.handleFiledClick,
          readonly: "",
          disabled: _ctx.disabled,
          "is-link": !_ctx.disabled
        }, _ctx.$attrs, { placeholder: "请选择" }), null, 16, ["modelValue", "onClick", "disabled", "is-link"])
      ]),
      _: 1
    }, 8, ["title", "required", "readonly"]),
    _createVNode(_component_uf_daterange_picker, {
      type: _ctx.options.date_type == '1' ? 'datetime' : 'date',
      require: _ctx.options.require,
      options: _ctx.options,
      show: _ctx.show,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = $event)),
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.date = $event)),
      onClear: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:modelValue', [])))
    }, null, 8, ["type", "require", "options", "show", "modelValue"])
  ], 64))
}