
import { factoryService } from "@/service";
import { useVModel } from "@vueuse/core";
import { Toast } from "vant";
import { defineComponent, reactive, onBeforeUpdate, ref, nextTick } from "vue";

export interface StoresInfo {
  attribute: string;
  attribute_name: string;
  id: string;
  info: string;
  owner_name: string;
  owner_tel: string;
  place_ids: string;
  place_lists: string[];
  place_true_names: string;
  stores_name: string;
  stores_sub: string;
  stores_sub_name: string;
  showPopover: boolean;
}

export default defineComponent({
  name: "UfStores",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ["confirm", "update:show"],
  setup(props, { emit }) {
    const popupShow = useVModel(props, "show", emit);

    const state = reactive({
      searchValue: "",
      list: [] as StoresInfo[]
    });

    const showCheckedList = ref(false);

    const placeRef = ref<any[]>([]);
    const placeBounding = ref<boolean[]>([]);
    const checkedPlaceRef = ref<any[]>([]);
    const checkedPlacePopover = ref<boolean[]>([]);

    const checked = ref<StoresInfo[]>([]);
    const checkboxRefs = ref<any[]>([]);
    const checkboxGroup = ref<any>(null);

    const toggle = async (index: number) => {
      if (!props.multiple) {
        checkboxGroup.value.toggleAll(false);
        await nextTick();
      }
      checkboxRefs.value[index].toggle();
    };

    onBeforeUpdate(() => {
      checkboxRefs.value = [];
      placeRef.value = [];
      checkedPlaceRef.value = [];
    });

    const addPlaceRef = async (el: Element, index: number) => {
      placeRef.value[index] = el;
      await nextTick();
      placeBounding.value[index] = el.getBoundingClientRect().right > window.innerWidth * 2;
    };

    const addCheckedPlaceRef = async (el: Element, index: number) => {
      checkedPlaceRef.value[index] = el;
    };

    const opened = () => {
      checkedPlaceRef.value.forEach((el, index) => {
        checkedPlacePopover.value[index] = el.getBoundingClientRect().right > window.innerWidth - 30;
      });
    };
    const opened1 = () => {
      placeRef.value.forEach((el, index) => {
        placeBounding.value[index] = el.getBoundingClientRect().right > window.innerWidth * 2;
      });
    };

    const getStoresLists = async () => {
      const { data } = await factoryService.storesLists({
        search_name: state.searchValue
      });
      data.data.forEach((item: any) => {
        item.showPopover = false;
      });
      state.list = data.data;
    };
    getStoresLists();

    const onSearch = () => {
      getStoresLists();
    };

    const leftButtonClick = () => {
      showCheckedList.value = true;
    };
    const checkedListConfirm = () => {
      showCheckedList.value = false;
    };

    const clear = () => {
      checkboxGroup.value.toggleAll(false);
      checkedListConfirm();
    };

    const confirm = () => {
      if (checked.value.length === 0) {
        return Toast.fail("请选择客户机构");
      }
      popupShow.value = false;
      emit("confirm", checked.value);
    };
    return {
      state,
      popupShow,
      onSearch,
      toggle,
      checked,
      checkboxRefs,
      placeRef,
      addPlaceRef,
      addCheckedPlaceRef,
      placeBounding,
      checkedPlacePopover,
      checkboxGroup,
      confirm,
      showCheckedList,
      leftButtonClick,
      clear,
      checkedListConfirm,
      opened,
      opened1
    };
  }
});
