
import { computed, defineComponent, PropType, toRefs } from "vue";
import { UfUploadFileType } from "@/composables";

export default defineComponent({
  name: "UfIdPhoto",
  props: {
    options: {
      type: Object as PropType<Approval.IdPhoto>,
      default: {},
      required: true
    },
    modelValue: {
      type: Array as PropType<UfUploadFileType[]>,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const fileList = computed<UfUploadFileType[]>({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });
    return {
      fileList,
      disabled
    };
  }
});
