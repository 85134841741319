import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { store } from "@/store";

export enum RouterName {
  HOME = "Home",
  LOGIN = "Login",
  MY = "My",
  MY_APPROVAL = "myApproval",
  APPROVAL_DETAIL = "approvalDetail",
  ASSOCIATE_APPROVAL = "associateApproval",
  ASSOCIATE_RESULT = "approvalResult",
  AUTHENTICATION = "authentication",
  STAFF_AUTH = "staffAuth",
  AUTHENTICATION_DETAIL = "authenticationDetail",
  STORES_AUTH = "storesAuth",
  FACTORY_AUTH = "factoryAuth",
  APPROVAL_START = "approvalStart",
  RULES_REGULATIONS = "rulesRegulations",
  PROCESS = "process",
  PASS_LIST = "passList",
  PAY = "pay",
  EVENTS_STRAT = "eventsStart",
  MY_EVENTS = "myEvents",
  EVENTS_DETAIL = "eventsDetail",
  EVENTS_LOG = "evnentsLog",
  MY_FIRE = "myFire",
  FIRE_DETAIL = "fireDetail",
  FIRE_LOG = "fireLog",
  VISITOR = "visitor",
  VISITOR_FORM = "visitorForm",
  VISITOR_DETAILS = "visitorDetails",
  VISITOR_MY = "visitorMy",
  VISITOR_INVITATION = "visitorInvitation",
  VISITOR_LIST = "visitorList",
  VISITOR_RESULT = "visitorResult",
  VISITOR_RECORD = "visitorRecord",
  SHOP_MANAGE_CHECK_UP = "shopManageCheckUp",
  OPEN_SHOP_INSPECT = "OpenShopInspect",
  EXTENSION_APPLICATION = "extensionApplication",
  STORE_PERSONNEL_MANAGEMENT = "storePersonnelManagement",
  PERSONNEL_DETAILS = "personnelDetails",
  ADD_PERSON = "addPerson",
  OPEN_CLOSE_SHOP_APPROVAL = "openCloseShopApproval",
  OPEN_CLOSE_SHOP_APPLY_DETAILS = "openCloseShopApplyDetails",
  OPERATION_RECORD = "operationRecord",
  DELAY_APPLY_DETAILS = "delayApplyDetails",
  DELAY_APPLY_OPERATION_RECORD = "delayApplyOperationRecord",
  ALARM_LIST = "alarm_list",
  ALARM_DETAIL = "alarm_detail"
}

const routes: Array<RouteRecordRaw> = [
  // 首页
  {
    path: "/",
    name: RouterName.HOME,
    component: () => import("@/views/home/Index.vue"),
    meta: {
      title: "我的审批"
    }
  },
  // 我的审批列表
  {
    path: "/myApproval",
    name: RouterName.MY_APPROVAL,
    component: () => import("@/views/my-approval/Index.vue"),
    meta: {
      title: "我的审批"
    }
  },
  // 审批详情
  {
    path: "/detail/:id",
    name: RouterName.APPROVAL_DETAIL,
    props: true,
    component: () => import("@/views/approval-detail/Index.vue"),
    meta: {
      title: "审批详情"
    }
  },
  // 审批开始
  {
    path: "/approvalStart/:id",
    name: RouterName.APPROVAL_START,
    props: true,
    component: () => import("@/views/approval-detail/ApprovalStart.vue"),
    meta: {
      title: "发起审批"
    }
  },
  // 查看规章制度
  {
    path: "/rulesRegulations",
    name: RouterName.RULES_REGULATIONS,
    props: true,
    component: () => import("@/views/rules-regulations/index.vue"),
    meta: {
      title: "规章制度"
    }
  },
  // 出入证
  {
    path: "/pass-list/:id",
    name: RouterName.PASS_LIST,
    props: true,
    component: () => import("@/views/approval-detail/PassList.vue"),
    meta: {
      title: "出入证"
    }
  },
  {
    path: "/approvalResult",
    name: RouterName.ASSOCIATE_RESULT,
    component: () => import("@/views/approval-detail/Result.vue"),
    meta: {
      title: "审批结果"
    }
  },
  {
    path: "/process/:id",
    name: RouterName.PROCESS,
    component: () => import("@/views/approval-detail/Process.vue"),
    meta: {
      title: "审批进度"
    }
  },
  // 事件模块
  {
    path: "/eventsStart/:id",
    name: RouterName.EVENTS_STRAT,
    props: true,
    component: () => import("@/views/events/EventsStart.vue"),
    meta: {
      title: "加载中..."
    }
  },
  // 我的报事
  {
    path: "/myEvents",
    name: RouterName.MY_EVENTS,
    props: true,
    component: () => import("@/views/events/MyEvents.vue"),
    meta: {
      title: "我的报事"
    }
  },
  // 事件详情
  {
    path: "/events/:id",
    name: RouterName.EVENTS_DETAIL,
    props: true,
    component: () => import("@/views/events/Detail.vue"),
    meta: {
      title: "事务详情"
    }
  }, // 事件详情
  {
    path: "/events-log/:id",
    name: RouterName.EVENTS_LOG,
    props: true,
    component: () => import("@/views/events/Log.vue"),
    meta: {
      title: "操作记录"
    }
  },
  // 消防模块-抄送我的报警
  {
    path: "/myFire",
    name: RouterName.MY_FIRE,
    props: true,
    component: () => import("@/views/fire/MyFire.vue"),
    meta: {
      title: "抄送我的报警"
    }
  },
  // 消防模块-报警详情
  {
    path: "/fire/:id",
    name: RouterName.FIRE_DETAIL,
    props: true,
    component: () => import("@/views/fire/Detail.vue"),
    meta: {
      title: "报警详情"
    }
  },
  // 消防模块-日志
  {
    path: "/fire-log/:id",
    name: RouterName.FIRE_LOG,
    props: true,
    component: () => import("@/views/fire/Log.vue"),
    meta: {
      title: "操作记录"
    }
  },
  // 访客
  {
    path: "/visitor/index",
    name: RouterName.VISITOR,
    component: () => import("@/views/visitor/index.vue"),
    meta: {
      authRequired: false,
      title: "访客申请"
    }
  },
  // 访客录入页
  {
    path: "/visitor/form",
    name: RouterName.VISITOR_FORM,
    component: () => import("@/views/visitor/form.vue"),
    meta: {
      title: "访客信息录入"
    }
  },
  //访客-审批详情
  {
    path: "/visitor/details",
    name: RouterName.VISITOR_DETAILS,
    component: () => import("@/views/visitor/details.vue"),
    meta: {
      title: "访客信息录入"
    }
  },
  //访客-审批列表
  {
    path: "/visitor/list",
    name: RouterName.VISITOR_LIST,
    component: () => import("@/views/visitor/list.vue"),
    meta: {
      title: "审批列表"
    }
  },
  //访客-申请记录
  {
    path: "/visitor/record",
    name: RouterName.VISITOR_RECORD,
    component: () => import("@/views/visitor/record.vue"),
    meta: {
      title: "申请记录"
    }
  },
  //访客-处理结果
  {
    path: "/visitor/result",
    name: RouterName.VISITOR_RESULT,
    component: () => import("@/views/visitor/result.vue"),
    meta: {
      title: "审批列表"
    }
  },
  {
    path: "/visitor/my",
    name: RouterName.VISITOR_MY,
    component: () => import("@/views/visitor/my.vue"),
    meta: {
      title: "我的邀请函"
    }
  },
  {
    path: "/visitor/my/:id",
    name: RouterName.VISITOR_INVITATION,
    component: () => import("@/views/visitor/invitation.vue"),
    meta: {
      title: "我的邀请函"
    }
  },
  // 店铺管理
  {
    path: "/shopManage/openAndCloseShopCheckUp",
    name: RouterName.SHOP_MANAGE_CHECK_UP,
    component: () => import("@/views/shop-manage/OpenAndCloseShopCheckUp.vue"),
    meta: {
      title: "店铺开闭店管理"
    }
  },
  // 开店检查
  {
    path: "/shopManage/OpenShopInspect",
    name: RouterName.OPEN_SHOP_INSPECT,
    component: () => import("@/views/shop-manage/OpenShopInspect.vue"),
    meta: {
      title: "开店检查"
    }
  },
  // 延期申请
  {
    path: "/shopManage/extensionApplication",
    name: RouterName.EXTENSION_APPLICATION,
    component: () => import("@/views/shop-manage/ExtensionApplications.vue"),
    meta: {
      title: "延期申请"
    }
  },
  // 人员详情
  {
    path: "/shopManage/personnelDetails",
    name: RouterName.PERSONNEL_DETAILS,
    component: () => import("@/views/shop-manage/PersonnelDetails.vue"),
    meta: {
      title: "人员详情"
    }
  },
  // 新增人员
  {
    path: "/shopManage/addPerson",
    name: RouterName.ADD_PERSON,
    component: () => import("@/views/shop-manage/AddPerson.vue"),
    meta: {
      title: "新增人员"
    }
  },
  // 店铺人员管理
  {
    path: "/shopManage/storePersonnelManagement",
    name: RouterName.STORE_PERSONNEL_MANAGEMENT,
    component: () => import("@/views/shop-manage/StorePersonnelManagement.vue"),
    meta: {
      title: "店铺人员管理"
    }
  },
  // 开闭店审批
  {
    path: "/shopManage/openCloseShopApproval",
    name: RouterName.OPEN_CLOSE_SHOP_APPROVAL,
    component: () => import("@/views/shop-manage/OpenCloseShopApproval.vue"),
    meta: {
      title: "开闭店审批"
    }
  },
  // 开闭店申请详情
  {
    path: "/shopManage/openCloseShopApplyDetails",
    name: RouterName.OPEN_CLOSE_SHOP_APPLY_DETAILS,
    component: () => import("@/views/shop-manage/OpenCloseShopApplyDetails.vue"),
    meta: {
      title: "开闭店申请详情"
    }
  },
  // 开闭店申请详情-操作记录
  {
    path: "/shopManage/operationRecord",
    name: RouterName.OPERATION_RECORD,
    component: () => import("@/views/shop-manage/OperationRecord.vue"),
    meta: {
      title: "操作记录"
    }
  },
  // 延期申请详情
  {
    path: "/shopManage/delayApplyDetails",
    name: RouterName.DELAY_APPLY_DETAILS,
    component: () => import("@/views/shop-manage/DelayApplyDetails.vue"),
    meta: {
      title: "延期申请详情"
    }
  },
  // 延期申请详情-操作记录
  {
    path: "/shopManage/delayApplyOperationRecord",
    name: RouterName.DELAY_APPLY_OPERATION_RECORD,
    component: () => import("@/views/shop-manage/DelayApplyOperationRecord.vue"),
    meta: {
      title: "操作记录"
    }
  },
  // 报警-列表
  {
    path: "/alarm/list",
    name: RouterName.ALARM_LIST,
    component: () => import("@/views/alarm/List.vue"),
    meta: {
      title: "报警列表"
    }
  },
  // 报警-详情
  {
    path: "/alarm/detail",
    name: RouterName.ALARM_DETAIL,
    component: () => import("@/views/alarm/Detail.vue"),
    meta: {
      title: "报警详情"
    }
  },
  // 个人中心
  {
    path: "/i",
    redirect: {
      name: RouterName.MY
    },
    component: () => import("@/views/Page.vue"),
    children: [
      // 我的
      {
        path: "",
        name: RouterName.MY,
        component: () => import("@/views/i/Index.vue"),
        meta: {
          title: "我的"
        }
      },
      // 权限认证
      {
        path: "authentication",
        name: RouterName.AUTHENTICATION,
        component: () => import("@/views/i/Authentication.vue"),
        meta: {
          title: "权限认证"
        }
      },
      // 详情
      {
        path: "detail",
        name: RouterName.AUTHENTICATION_DETAIL,
        component: () => import("@/views/i/auth/Detail.vue"),
        meta: {
          title: "认证详情"
        }
      },
      // 人员认证
      {
        path: "staffAuth",
        name: RouterName.STAFF_AUTH,
        component: () => import("@/views/i/auth/Staff.vue"),
        meta: {
          title: "人员认证"
        }
      },
      // 外部客户认证
      {
        path: "storesAuth",
        name: RouterName.STORES_AUTH,
        component: () => import("@/views/i/auth/Stores.vue"),
        meta: {
          title: "客户认证"
        }
      },
      // 外部商家认证
      {
        path: "factoryAuth",
        name: RouterName.FACTORY_AUTH,
        component: () => import("@/views/i/auth/Factory.vue"),
        meta: {
          title: "商家认证"
        }
      }
    ]
  },
  // 登录
  {
    path: "/login",
    name: RouterName.LOGIN,
    component: () => import("@/views/Login.vue"),
    meta: {
      authRequired: false,
      title: "登录"
    }
  },
  // 支付
  {
    path: "/pay",
    name: RouterName.PAY,
    component: () => import("@/views/Pay.vue"),
    meta: {
      authRequired: false,
      title: "微信支付"
    }
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

const canUserAccess = (to: RouteLocationNormalized) => {
  // 如果该页面不需要用登录直接返回true
  if (to.matched.some(record => record.meta.authRequired === false)) return true;
  // 要么就返回当前是否有用户信息
  return !!store.state.userInfo;
};
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || "审批";

  if (to.query.token) {
    store.commit("setToken", to.query.token);
    store.commit("setUserInfo", null);
  }
  // 每次刷新页面，有token，但是没有用户信息，就去获取用户信息，当然也有可能获取用户信息失败，比如token过期
  if (store.state.token && !store.state.userInfo) {
    await store.dispatch("getUserInfo");
  }
  // 如果有用户信息，且要访问的是登录页面，就跳首页
  if (store.state.userInfo && [RouterName.LOGIN].includes(to.name as RouterName)) {
    next({ name: RouterName.HOME, replace: true });
    return;
  }
  // 验证去往的页面是否能通过验证
  const isAuthenticated = canUserAccess(to);
  // 没通过验证就跳登录页面，通过就跳转
  if (!isAuthenticated) next({ name: RouterName.LOGIN, replace: true });
  else next();
});

export default router;
