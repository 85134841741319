import { api } from "@/utils/axios";

interface IMyVisitorListsBody {
  page: number;
  pagesize: number;
}

export const visitorService = {
  myVisitorLists(body: IMyVisitorListsBody) {
    return api.post<PageResponseData>("/Visitor/my_visitor_lists", body);
  },
  configInfo() {
    return api.post<ResponseData>("Visitor/config_info");
  },
  add(body: any) {
    return api.post<ResponseData>("Visitor/visitor_add", body);
  },
  myVisitorDetail(body: any) {
    return api.post<ResponseData>("Software/my_visitor_detail", body);
  },
  visitorLists(body: any) {
    return api.post<PageResponseData>("Visitor/visitor_lists", body);
  },
  visitorDetail(id: string) {
    return api.post<ResponseData>("Visitor/visitor_detail", { id });
  },
  visitorOpt(body: any) {
    return api.post<ResponseData>("Visitor/visitor_opt", body);
  }
};
