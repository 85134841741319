
import { PlaceTreeNode } from "@/components/location/Tree.vue";
import { computed, defineComponent, PropType, ref, toRefs, inject, onBeforeUpdate } from "vue";

export default defineComponent({
  name: "UfPlace",
  props: {
    defalut: {
      type: Object as PropType<PlaceTreeNode>,
      default: () => ({
        place: "",
        id: ""
      })
    },
    options: {
      type: Object as PropType<Approval.Place>,
      default: {},
      required: true
    }
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const { defalut, options } = toRefs(props);
    const isEventDetail = inject("isEventDetail");
    let defalutValue = defalut.value.place;
    if (options.value.place_arr) {
      options.value.place_arr.forEach(item => {
        item.showPopover = false;
      });
      const names = options.value.place_arr.map(item => item.true_place);
      defalutValue = names.length > 1 ? `已选${options.value.place_arr.length}个位置` : names.join(",");
    }
    const value = ref(defalutValue);
    const disabled = computed(() => options.value.power == 1);
    // 显示
    const show = ref(false);
    const showCheckedList = ref(false);

    const onClick = () => {
      if (disabled.value) {
        if (options.value.place_arr) {
          if (options.value.place_arr.length > 1) {
            showCheckedList.value = true;
          }
        }
        return;
      }
      show.value = true;
    };
    const confirm = (data: PlaceTreeNode | PlaceTreeNode[]) => {
      if (Array.isArray(data)) {
        const names = data.map(item => item.true_place_name);
        value.value = names.length > 1 ? `已选${names.length}个位置` : names.join(",");
      } else {
        value.value = data.true_place_name;
      }
      emit("confirm", data);
    };
    // 已选
    const checkedPlacePopover = ref<boolean[]>([]);
    const checkedPlaceRef = ref<any[]>([]);
    onBeforeUpdate(() => {
      checkedPlaceRef.value = [];
    });

    const addCheckedPlaceRef = async (el: Element, index: number) => {
      checkedPlaceRef.value[index] = el;
    };

    const opened = () => {
      checkedPlaceRef.value.forEach((el, index) => {
        checkedPlacePopover.value[index] = el.getBoundingClientRect().right > window.innerWidth - 30;
      });
    };
    return {
      confirm,
      show,
      onClick,
      value,
      disabled,
      isEventDetail,
      showCheckedList,
      checkedPlaceRef,
      checkedPlacePopover,
      addCheckedPlaceRef,
      opened
    };
  }
});
