import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "uf-uploader" }
const _hoisted_2 = { class: "uf-uploader__preview-image" }
const _hoisted_3 = { class: "uf-uploader__preview-content" }
const _hoisted_4 = { class: "uf-uploader__preview-content-title" }
const _hoisted_5 = { class: "uf-uploader__preview-content-size" }
const _hoisted_6 = {
  key: 1,
  class: "uf-uploader__preview-progress"
}
const _hoisted_7 = { class: "uf-uploader-btn" }
const _hoisted_8 = {
  key: 0,
  class: "uf-uploader__tip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_progress = _resolveComponent("van-progress")
  const _component_van_uploader = _resolveComponent("van-uploader")
  const _component_van_action_sheet = _resolveComponent("van-action-sheet")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fileList, (file, index) => {
        return (_openBlock(), _createBlock("div", {
          class: "uf-uploader__preview",
          key: file.res.id,
          onClick: ($event: any) => (_ctx.download(file))
        }, [
          (file.status === 'done')
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                _createVNode("div", _hoisted_2, [
                  _createVNode(_component_van_icon, { name: "column" })
                ]),
                _createVNode("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, _toDisplayString(file.res.file_name), 1),
                  _createVNode("div", _hoisted_5, _toDisplayString(file.res.file_size) + "KB", 1)
                ]),
                (!_ctx.disabled)
                  ? (_openBlock(), _createBlock("div", {
                      key: 0,
                      class: "uf-uploader__preview-delete",
                      onClick: ($event: any) => (_ctx.removeUploaded(_ctx.fileList, index))
                    }, [
                      _createVNode(_component_van_icon, { name: "clear" })
                    ], 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 64))
            : (_openBlock(), _createBlock("div", _hoisted_6, [
                _createVNode(_component_van_progress, {
                  percentage: file.progress,
                  "stroke-width": "8"
                }, null, 8, ["percentage"])
              ]))
        ], 8, ["onClick"]))
      }), 128)),
      _createVNode(_component_van_uploader, {
        modelValue: _ctx.fileList,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.fileList = $event)),
        "max-count": _ctx.maxCount,
        "max-size": _ctx.maxSize,
        "after-read": _ctx.upload,
        deletable: !_ctx.disabled,
        "show-upload": !_ctx.disabled,
        accept: _ctx.accept,
        onOversize: _ctx.onOversize
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_van_icon, { name: "plus" })
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "max-count", "max-size", "after-read", "deletable", "show-upload", "accept", "onOversize"]),
      (_ctx.tipText && _ctx.fileList.length === 0)
        ? (_openBlock(), _createBlock("div", _hoisted_8, _toDisplayString(_ctx.tipText), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_van_action_sheet, {
      show: _ctx.show,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = $event)),
      actions: _ctx.actions,
      "cancel-text": "取消",
      "close-on-click-action": "",
      onSelect: _ctx.onSelect
    }, null, 8, ["show", "actions", "onSelect"])
  ], 64))
}