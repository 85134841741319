import { createApp } from "vue";
import "normalize.css/normalize.css";
import "amfe-flexible";
import App from "./App.vue";
import router, { RouterName } from "./router";
import { store, key } from "./store";

import components from "@/components";

import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/styles/index.less";

const app = createApp(App);
app
  .use(store, key)
  .use(router)
  .use(Vant)
  .use(components)
  .mount("#app");

app.config.globalProperties.$RouterName = RouterName;
