import { api } from "@/utils/axios";

export const storesManageService = {
  toOcStores(storesId: string) {
    return api.post<ResponseData>("/Stores_manage/to_oc_stores", {
      stores_id: storesId
    });
  },
  storesOcLists(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_oc_lists", body);
  },
  storesDelayLists(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_delay_lists", body);
  },
  storesDelay(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_delay", body);
  },
  ocDelayReasons() {
    return api.post<ResponseData>("/Stores_manage/oc_delay_reasons");
  },
  toOcReady(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/to_oc_ready", body);
  },
  storesOc(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_oc", body);
  },

  storesDelayApproval(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_delay_approval", body);
  },

  storesOCDetail(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_oc_detail", body);
  },
  storesDelayDetail(body: any = {}) {
    return api.post<ResponseData>("Stores_manage/stores_delay_detail", body);
  },
  storesOCApproval(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/stores_oc_approval", body);
  },
  ocOptLists(body: any = {}) {
    return api.post<ResponseData>("/Stores_manage/oc_opt_lists", body);
  },
  userAdd(body: any = {}) {
    return api.post<ResponseData>("/User/user_add", body);
  },
  userEdit(body: any = {}) {
    return api.post<ResponseData>("/User/user_edit", body);
  },
  delUser(body: any = {}) {
    return api.post<ResponseData>("/User/del_user", body);
  }
};
