
import { defineComponent, inject, PropType, toRefs, computed } from "vue";
import { PlaceTreeNode } from "./Tree.vue";

export default defineComponent({
  name: "UfTreeNode",
  props: {
    node: {
      type: Object as PropType<PlaceTreeNode>,
      required: true
    }
  },
  setup(props) {
    const { node } = toRefs(props);
    const multiple = inject<boolean>("multiple");

    // 使用扩展
    const userExpandedKeys = inject<string[]>("expandedKeys");
    const updateExpandedKeys = inject<(keys: string) => void>("updateExpandedKeys");
    // 使用已选择的
    const userChecked = inject<PlaceTreeNode>("checked");

    const userCheckedList = inject<PlaceTreeNode[]>("checkedList", []);

    const updateChecked = inject<(node: PlaceTreeNode) => void>("updateChecked");
    const onclick = () => {
      updateChecked?.(node.value);
    };

    const moreClick = () => {
      updateExpandedKeys?.(node.value.ppath);
    };

    const isChecked = computed(() => {
      if (multiple) {
        return userCheckedList.some(item => item === node.value);
      }
      return userChecked?.id === node.value.id;
    });

    const hasMore = computed(() => {
      return userExpandedKeys?.some(item => item === node.value.ppath);
    });

    return {
      userChecked,
      userCheckedList,
      userExpandedKeys,
      onclick,
      moreClick,
      multiple,
      isChecked,
      hasMore
    };
  }
});
