import { reactive } from "vue";
import moment from "moment";
import { UfUploadFileType } from ".";
import _ from "lodash";

export interface PlaceTreeNode {
  id: string;
  level: string;
  lists: PlaceTreeNode[];
  pid: string;
  place: string;
  ppath: string;
  qrcode: string;
  qrcode_encode: string;
  rfid: string;
  sort: string;
  true_place_name: string;
}

export enum ComponentsType {
  RADIO = "check_radio",
  CHECKBOX = "check_checkbox",
  TEXTAREA = "check_textarea",
  PIC = "check_pic",
  ASSIGN = "check_assign",
  NUMBER = "check_number",
  FILE = "check_file",
  MONEY = "check_money",
  DATE = "check_date",
  USER = "check_user",
  SHOW = "check_show",
  RELATION = "check_relation",
  DETAIL = "check_detail",
  TEXT = "check_text",
  PLACE = "check_place",
  DATE_SLOT = "check_dateslot",
  COMPANY = "check_company",
  REGION = "check_region",
  USERINFO = "check_userinfo",
  ID_PHOTO = "check_id_photo",
  NVQ = "check_nvq",
  STORES = "check_stores"
}

export function useTempFormData(origin: Approval.SubComponents) {
  // 格式化给后端想要的数据方法
  const formatMap = {
    [ComponentsType.RADIO]: (value: number) => value,
    [ComponentsType.CHECKBOX]: (value: string[]) => value.toString(),
    [ComponentsType.DATE_SLOT]: (date: number[], type: string) => {
      if (date.length === 0) return "";
      const format = type == "1" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
      return moment(date[0]).format(format) + " - " + moment(date[1]).format(format);
    },
    [ComponentsType.PLACE]: (place: PlaceTreeNode | PlaceTreeNode[]) => {
      if (Array.isArray(place)) {
        return place.map(item => item.id).join(",");
      }
      return place.id || "";
    },
    [ComponentsType.PIC]: (files: UfUploadFileType[]) =>
      files
        .map(file => file?.res.id)
        .filter(i => i)
        .join(","),
    [ComponentsType.FILE]: (files: UfUploadFileType[]) =>
      files
        .map(file => file?.res.id)
        .filter(i => i)
        .join(","),
    [ComponentsType.ID_PHOTO]: (files: UfUploadFileType[]) =>
      files
        .map(file => file?.res.id)
        .filter(i => i)
        .join(","),
    [ComponentsType.REGION]: (data: any) => {
      if (Array.isArray(data)) return "";
      return data;
    }
  };
  // 初始化给组件使用的数组
  const initializeFormData = (origin: Approval.SubComponents) => {
    const formData: Dictionary<any> = {};
    const initializeFile = (item: Approval.FileRes) => {
      return {
        content: "",
        message: "上传成功",
        status: "done",
        url: item.photo_file,
        progress: 100,
        res: item
      };
    };
    // 创建ID与组件类型之间的联系
    const typeOfRelationship: Dictionary<string> = {};

    const initializeValue = (item: Approval.SubComponent) => {
      if (item.type === ComponentsType.RADIO) {
        const find = item.option.find(item => item.is_default === 1);
        return item.result || (find ? find.key : "");
      }
      if (item.type === ComponentsType.CHECKBOX) {
        return item.result
          ? item.result.split(",").map(i => Number(i))
          : item.option.filter(item => item.is_default === 1).map(item => item.key);
      }
      if (item.type === ComponentsType.TEXTAREA) return item.result || "";
      if (item.type === ComponentsType.ASSIGN) return item.result || "";
      if (item.type === ComponentsType.NUMBER) return item.result || "";
      if (item.type === ComponentsType.PIC) return item.pic_arr?.map(item => initializeFile(item)) || [];
      if (item.type === ComponentsType.FILE) return item.pic_arr?.map(item => initializeFile(item)) || [];
      if (item.type === ComponentsType.ID_PHOTO) return item.pic_arr?.map(item => initializeFile(item)) || [];
      if (item.type === ComponentsType.MONEY) return item.result || "";
      if (item.type === ComponentsType.DATE) return item.result || "";
      if (item.type === ComponentsType.USER) return item.result || "";
      if (item.type === ComponentsType.STORES) return item.result || "";
      if (item.type === ComponentsType.SHOW) return item.content || "";
      if (item.type === ComponentsType.RELATION) return "";
      if (item.type === ComponentsType.DETAIL) return [];
      if (item.type === ComponentsType.TEXT) return item.result || "";
      if (item.type === ComponentsType.PLACE)
        return {
          id: item.result,
          place: item.true_place || item.place
        };
      if (item.type === ComponentsType.DATE_SLOT) return item.result ? item.result.split(" - ") : [];
      if (item.type === ComponentsType.COMPANY) return [];
      if (item.type === ComponentsType.REGION) return item.result || [];
      if (item.type === ComponentsType.USERINFO) return [];
      if (item.type === ComponentsType.NVQ) return "";
      return "";
    };
    origin.forEach(item => {
      formData[item.id] = initializeValue(item);
      typeOfRelationship[item.id] = item.type;
    });
    return { formData, typeOfRelationship };
  };

  const { formData: originalFormData, typeOfRelationship } = initializeFormData(origin);
  const formData = reactive(originalFormData);

  // 通过ID与类型的联系再从格式化的方法中去格式化数据，给后端使用
  const getFormattedData = () => {
    const formattedData = _.cloneDeep(formData);
    Object.keys(formattedData).forEach(key => {
      if (formatMap[typeOfRelationship[key]]) {
        if (typeOfRelationship[key] === ComponentsType.DATE_SLOT) {
          const find = origin.find(item => item.id == key);
          formattedData[key] = formatMap[typeOfRelationship[key]](
            formattedData[key],
            (find as Approval.Dateslot)?.date_type
          );
          return;
        }
        formattedData[key] = formatMap[typeOfRelationship[key]](formattedData[key]);
      }
    });
    return formattedData;
  };

  return {
    formData,
    getFormattedData
  };
}
