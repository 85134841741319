
import { computed, defineComponent, PropType, ref, toRefs, watchEffect } from "vue";
export default defineComponent({
  name: "UfRelation",
  props: {
    options: {
      type: Object as PropType<Approval.Relation>,
      default: {},
      required: true
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { options, modelValue } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });
    const list = ref<Approval.RelationItem[]>(options.value.relation_lists || []);
    watchEffect(() => {
      value.value = list.value.map(i => i.id).join(",");
    });
    const show = ref(false);
    const onClick = () => {
      if (disabled.value) return;
      show.value = true;
    };
    const onDelete = (index: number) => {
      list.value.splice(index, 1);
    };
    const confirm = (data: Approval.RelationItem[]) => {
      list.value = data;
    };

    const classes = (item: Approval.RelationItem) => {
      let className = "";
      if (item.state == "1") {
        className = "tag--primary";
      } else if (item.state == "2") {
        className = "tag--default";
      } else if (item.state == "3") {
        className = "tag__warning";
      }
      return {
        [className]: true,
        tag: true
      };
    };
    return {
      confirm,
      show,
      onClick,
      onDelete,
      value,
      disabled,
      list,
      classes
    };
  }
});
