
import { computed, defineComponent, PropType, toRefs, provide, readonly, reactive, inject } from "vue";
import UfTreeNode from "./TreeNode.vue";

export interface DepartmentTreeNode {
  department: string;
  id: string;
  level: string;
  lists: DepartmentTreeNode[];
  pid: string;
  ppath: string;
  true_department: string;
}

export interface UfTreeType {
  checkedList: DepartmentTreeNode[];
  getCurrentNode: () => DepartmentTreeNode | DepartmentTreeNode[];
}

export default defineComponent({
  name: "UfTree",
  components: {
    UfTreeNode
  },
  props: {
    data: {
      type: Array as PropType<DepartmentTreeNode[]>,
      required: true
    }
  },
  setup(props) {
    // 获取数据
    const { data } = toRefs(props);

    const multiple = inject<boolean>("multiple");

    const innerData = computed(() => data.value);
    // 默认展开的值
    const expandedKeys = reactive<string[]>([]);
    const updateExpandedKeys = (keys: string) => {
      const idx = expandedKeys.findIndex(_keys => _keys === keys);
      if (idx != -1) {
        expandedKeys.splice(idx, 1);
        return;
      }
      expandedKeys.push(keys);
      return;
    };
    provide("expandedKeys", readonly(expandedKeys));
    provide("updateExpandedKeys", updateExpandedKeys);
    // 选中的值
    const checked = reactive<DepartmentTreeNode | {}>({});
    // 多选的话
    const checkedList = reactive<DepartmentTreeNode[]>([]);

    const updateChecked = (data: DepartmentTreeNode) => {
      if (multiple) {
        const idx = checkedList.findIndex(checked => checked === data);
        if (idx != -1) {
          checkedList.splice(idx, 1);
          return;
        }
        checkedList.push(data);
        return;
      }
      Object.assign(checked, data);
    };
    provide("checked", readonly(checked));
    provide("checkedList", checkedList);

    provide("updateChecked", updateChecked);
    // 返回值
    const getCurrentNode = () => {
      if (multiple) {
        return checkedList;
      }
      return checked;
    };
    return {
      innerData,
      checked,
      checkedList,
      getCurrentNode
    };
  }
});
