
import { computed, defineComponent, PropType, toRefs, inject } from "vue";

export default defineComponent({
  name: "UfNumber",
  props: {
    options: {
      type: Object as PropType<Approval.Numeric>,
      default: {},
      required: true
    },
    type: {
      type: String,
      default: "number"
    },
    modelValue: {
      type: String as PropType<string>,
      default: ""
    },
    maxlength: {
      type: Number,
      default: 100
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isEventDetail = inject("isEventDetail");
    const { modelValue, options } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit("update:modelValue", val);
      }
    });
    const formatterFn = (value: string) => {
      const index = value.indexOf(".");
      const digitNum = options.value.digit_num || 1;
      const decimal_places = options.value.decimal_show ? Number(digitNum) : 0;
      if (index >= 0 && value.length - index > decimal_places + 1) {
        return value.substring(0, index + decimal_places + 1);
      }
      return value;
    };
    return {
      formatterFn,
      value,
      disabled,
      isEventDetail
    };
  }
});
