
import { computed, defineComponent, PropType, toRefs, reactive } from "vue";
import { UfUploadFileType, useUpload } from "@/composables";
import VuePictureCropper, { cropper } from "vue-picture-cropper";

export default defineComponent({
  name: "UfImage",
  components: {
    VuePictureCropper
  },
  props: {
    options: {
      type: Object as PropType<Approval.Pic>,
      default: {},
      required: true
    },
    type: {
      type: String,
      default: "number"
    },
    modelValue: {
      type: Array as PropType<UfUploadFileType[]>,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const fileList = computed<UfUploadFileType[]>({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });
    const lenInfo = computed(() => {
      return disabled.value ? "" : `${fileList.value.length}/${options.value.num_max}`;
    });
    const cropperInfo = reactive({
      show: false,
      pic: "",
      cancel() {},
      confirm() {}
    });
    const beforeRead = (file: File) => {
      if (options.value.field !== "photo") return true;
      return new Promise((resolve, reject) => {
        cropperInfo.pic = "";
        // 转换为base64传给裁切组件
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          // 更新裁切弹窗的图片源
          cropperInfo.pic = String(reader.result);
          cropperInfo.show = true;
        };
        cropperInfo.cancel = () => {
          cropperInfo.show = false;
          reject();
        };
        cropperInfo.confirm = async () => {
          const f = await cropper.getFile({
            fileName: file.name
          });
          cropperInfo.show = false;
          resolve(f);
        };
      });
    };
    const { upload } = useUpload();

    return {
      cropperInfo,
      fileList,
      upload,
      beforeRead,
      lenInfo,
      disabled
    };
  }
});
