
import { computed, defineComponent, inject, PropType, ref, toRefs } from "vue";
import List from "./List.vue";

interface ListItem {
  value: number;
  label: string;
}

export default defineComponent({
  name: "UfCheckbox",
  components: {
    List
  },
  props: {
    options: {
      type: Object as PropType<Approval.Checkbox>,
      default: {},
      required: true
    },
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const isEventDetail = inject("isEventDetail");
    const { options, modelValue } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const show = ref(false);
    const handleFiledClick = () => {
      if (disabled.value) return;
      show.value = true;
    };
    const checkedVal = ref([]);
    const innerOptions = computed(() => {
      return options.value.option.map(item => ({
        label: item.value,
        value: Number(item.key)
      }));
    });
    const value = computed({
      get() {
        const items = innerOptions.value.filter(item => {
          return modelValue.value.includes(Number(item.value));
        });
        return items.length === 0 ? "" : items.map((item: ListItem) => item.label).join(",");
      },
      set(value) {
        emit("update:modelValue", value ? value : []);
      }
    });

    const handleClearVal = () => {
      checkedVal.value = [];
    };
    const handleCancel = () => {
      show.value = false;
    };
    const handleOk = () => {
      value.value = checkedVal.value;
      handleCancel();
    };
    return {
      show,
      value,
      handleFiledClick,
      handleClearVal,
      handleOk,
      handleCancel,
      innerOptions,
      disabled,
      checkedVal,
      isEventDetail
    };
  }
});
