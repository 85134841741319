
import { computed, defineComponent, PropType, toRefs, inject } from "vue";

export default defineComponent({
  name: "UfTextarea",
  props: {
    modelValue: {
      type: String as PropType<string>,
      default: ""
    },
    options: {
      type: Object as PropType<Approval.Textarea>,
      default: {},
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);
    const isEventDetail = inject("isEventDetail");
    const disabled = computed(() => options.value.power == 1);
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit("update:modelValue", val);
      }
    });
    return { value, disabled, isEventDetail };
  }
});
