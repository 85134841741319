
import { computed, defineComponent, PropType } from "vue";
import { useUpload, UfUploadFileType } from "@/composables";

export default defineComponent({
  name: "UfIdcardUpload",
  props: {
    modelValue: {
      type: Array as PropType<UfUploadFileType[]>,
      required: true
    },
    disabled: Boolean
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const h = computed(() => props.modelValue[0]);
    const b = computed(() => props.modelValue[1]);
    const headerFileList = computed<UfUploadFileType[]>({
      get() {
        return h.value ? [h.value] : [];
      },
      set(value) {
        emit("update:modelValue", [value[0], b.value]);
      }
    });
    const backFileList = computed<UfUploadFileType[]>({
      get() {
        return b.value ? [b.value] : [];
      },
      set(value) {
        emit("update:modelValue", [h.value, value[0]]);
      }
    });

    const { upload } = useUpload("idcard");

    return {
      headerFileList,
      backFileList,
      upload
    };
  }
});
