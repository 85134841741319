
import { mydbService } from "@/service";
import { computed, defineComponent, PropType, reactive, toRefs } from "vue";
export default defineComponent({
  name: "UfRegion",
  props: {
    options: {
      type: Object as PropType<Approval.Region>,
      default: {},
      required: true
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { options } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const areaPicker = reactive({
      showPicker: false,
      label: options.value.region || ""
    });
    const areaList = reactive<Dictionary<Dictionary<string>>>({
      province_list: {},
      city_list: {},
      county_list: {}
    });
    const getAreaList = async () => {
      try {
        const { data } = await mydbService.allRegionLists();
        data.data.forEach(item => {
          if (item.pid_code == "0") {
            areaList.province_list[item.code] = item.name;
          }
        });
        data.data.forEach(item => {
          if (areaList.province_list[item.pid_code]) {
            areaList.city_list[item.code] = item.name;
          }
        });
        data.data.forEach(item => {
          if (areaList.city_list[item.pid_code]) {
            areaList.county_list[item.code.substr(0, 6)] = item.name;
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    getAreaList();
    const onClick = () => {
      if (disabled.value) return;
      areaPicker.showPicker = true;
    };
    const onCancel = () => {
      areaPicker.showPicker = false;
    };
    const onConfirm = (values: any[]) => {
      areaPicker.label = values.map(value => value.name).join("-");
      const arr = values.map(value => value.code);
      arr.forEach((el, index) => {
        if (index > 1) {
          arr[index] = el + "000000";
        }
      });
      emit("update:modelValue", arr.join(","));
      onCancel();
    };

    return {
      areaPicker,
      onClick,
      onConfirm,
      areaList,
      onCancel,
      disabled
    };
  }
});
