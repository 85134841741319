import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "uf-tree" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uf_tree_node = _resolveComponent("uf-tree-node")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.innerData, (item) => {
      return (_openBlock(), _createBlock(_component_uf_tree_node, {
        key: item.id,
        node: item
      }, null, 8, ["node"]))
    }), 128))
  ]))
}