import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")

  return (_openBlock(), _createBlock(_component_uf_form_item, {
    title: _ctx.options.title,
    readonly: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_field, _mergeProps({
        readonly: "",
        type: "textarea",
        autosize: "",
        rows: "1"
      }, _ctx.$attrs), null, 16)
    ]),
    _: 1
  }, 8, ["title", "readonly"]))
}