<template>
  <van-form v-bind="$attrs" class="uf-form">
    <slot></slot>
  </van-form>
</template>

<script>
export default {
  name: "UfForm"
};
</script>

<style scoped></style>
