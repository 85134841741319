<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ListItemTag",
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  computed: {
    classes() {
      let className = null;
      if (this.type == 1) {
        // type 0草稿 1审批中 2已通过 3未通过
        className = "ListItem__tag--primary";
      } else if (this.type == 2) {
        className = "ListItem__tag--default";
      } else if (this.type == 3) {
        className = "ListItem__tag__warning";
      }
      return {
        [className]: true,
        ListItem__tag: true
      };
    }
  }
});
</script>

<style scoped lang="less">
.ListItem {
  &__tag {
    font-size: 13px;
    color: #fff;
    border-radius: 8px 0 0 8px;
    padding: 5px 8px;
    flex-shrink: 0;
    margin-left: 10px;
    &--default {
      background-image: linear-gradient(270deg, #c6c9d1 0%, #9699a2 99%);
    }

    &--primary {
      background-image: linear-gradient(270deg, #3484ff 0%, #0065ff 98%);
    }

    &__warning {
      background-image: linear-gradient(270deg, #ff8264 0%, #ff5b34 100%);
    }

    &__success {
      background-image: linear-gradient(270deg, #5ee09f 0%, #1ed278 100%);
    }
  }
}
</style>
