import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_uf_upload = _resolveComponent("uf-upload")
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")

  return (_openBlock(), _createBlock(_component_uf_form_item, {
    title: _ctx.options.title,
    required: _ctx.options.is_checked == '1',
    readonly: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_field, null, {
        input: _withCtx(() => [
          _createVNode(_component_uf_upload, {
            modelValue: _ctx.licenseFile,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.licenseFile = $event)),
            "max-count": _ctx.options.num_max,
            "max-size": 20 * 1024 * 1024,
            disabled: _ctx.disabled
          }, null, 8, ["modelValue", "max-count", "disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "required", "readonly"]))
}