import { api } from "@/utils/axios";

interface StoresListsBody {
  search_name: string;
}

export const factoryService = {
  storesSubLists() {
    return api.post<ResponseData<{ id: string; stores_sub: string }[]>>("/Factory/stores_sub_lists");
  },
  storesLists(body: StoresListsBody) {
    return api.post<ResponseData>("/Factory/stores_lists", {
      page: "1",
      pagesize: "99999",
      more_place: "1",
      ...body
    });
  },
  detail(id: any) {
    return api.post<ResponseData>("/Factory/stores_detail", { id });
  }
};
