
import { defineComponent, PropType } from "vue";

interface ListItem {
  value: number;
  label: string;
}

export default defineComponent({
  name: "List",
  props: {
    options: {
      type: Array as PropType<ListItem[]>,
      required: true
    },
    modelValue: {
      type: Number,
      required: false
    }
  },
  emits: ["update:modelValue"],
  computed: {
    checkedVal: {
      get(): number | undefined {
        return this.modelValue;
      },
      set(value: any): void {
        this.$emit("update:modelValue", value ? value : null);
      }
    }
  }
});
