import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")
  const _component_uf_popup = _resolveComponent("uf-popup")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_uf_form_item, {
      title: _ctx.options.title,
      required: _ctx.options.is_checked == '1',
      readonly: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, _mergeProps({
          modelValue: _ctx.showVal,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showVal = $event)),
          onClick: _ctx.handleFiledClick,
          readonly: "",
          disabled: _ctx.disabled,
          "is-link": !_ctx.disabled
        }, _ctx.$attrs, { placeholder: "请选择" }), null, 16, ["modelValue", "onClick", "disabled", "is-link"])
      ]),
      _: 1
    }, 8, ["title", "required", "readonly"]),
    _createVNode(_component_uf_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => (_ctx.show = $event)),
      position: "bottom",
      "safe-area-inset-bottom": "",
      onCancel: _ctx.onCancel,
      onOk: _ctx.onOK,
      onClear: _ctx.onClear
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_datetime_picker, {
          type: _ctx.options.date_type == '1' ? 'datetime' : 'date',
          modelValue: _ctx.date,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.date = $event)),
          "show-toolbar": false,
          "min-date": _ctx.minDate,
          "max-date": _ctx.maxDate,
          formatter: _ctx.formatter
        }, null, 8, ["type", "modelValue", "min-date", "max-date", "formatter"])
      ]),
      _: 1
    }, 8, ["show", "onCancel", "onOk", "onClear"])
  ], 64))
}