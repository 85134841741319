
import moment from "moment";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

export default defineComponent({
  name: "UfDateSlot",
  props: {
    options: {
      type: Object as PropType<Approval.Dateslot>,
      default: {},
      required: true
    },
    modelValue: {
      type: Array as PropType<string[]>,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);
    const show = ref(false);
    const disabled = computed(() => options.value.power == 1);

    const handleFiledClick = () => {
      if (disabled.value) return;
      show.value = true;
    };

    const date = computed({
      get() {
        return modelValue.value;
      },
      set(val: string[]) {
        emit("update:modelValue", [moment(val[0]).valueOf(), moment(val[1]).valueOf()]);
      }
    });
    const showVal = computed(() => {
      if (date.value.length === 0) return "";
      const startDate = date.value[0];
      const endDate = date.value[1];
      const format = options.value.date_type == "1" ? "yyyy.MM.DD HH:mm" : "yyyy.MM.DD";
      return moment(startDate).format(format) + " - " + moment(endDate).format(format);
    });
    return {
      show,
      handleFiledClick,
      date,
      showVal,
      disabled
    };
  }
});
