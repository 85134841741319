import { userService, factoryService, mydbService } from "@/service";
import { store } from "@/store";
import { computed, reactive } from "vue";

enum Single {
  GENDER = "Gender",
  CERTIFICATE_TYPE = "CertificateType",
  STORES_TYPE = "StoresType",
  STORES_SUB_TYPE = "StoresSubType"
}

enum Multiple {
  AREA = "Area"
}

interface Option {
  defaultValue?: string;
  defaultLabel?: string;
}

export interface PickerColumn {
  text: string;
  value: string;
  parentId?: string;
  children?: PickerColumn[];
}

export interface Picker<T = "Single" | "Multiple"> {
  label: string;
  value: T extends "Single" ? string : string[];
  showPicker: boolean;
  columns: any;
  onConfirm: (label: T extends "Single" ? PickerColumn : PickerColumn[], index: number) => void;
  onCancel: (label: T extends "Single" ? PickerColumn : PickerColumn[], index: number) => void;
  onChange: (label: T extends "Single" ? PickerColumn : PickerColumn[], index: number) => void;
}

const columnsRepositories = reactive<Record<Single | Multiple, PickerColumn[]>>({
  [Single.GENDER]: [
    {
      text: "男",
      value: "1"
    },
    {
      text: "女",
      value: "2"
    }
  ],
  [Single.CERTIFICATE_TYPE]: [],
  [Single.STORES_TYPE]: [
    {
      text: "公司",
      value: "1"
    },
    {
      text: "个人",
      value: "2"
    }
  ],
  [Single.STORES_SUB_TYPE]: [],
  [Multiple.AREA]: []
});
const getCertificateType = async () => {
  const { data } = await userService.getCertificateType();
  columnsRepositories[Single.CERTIFICATE_TYPE] = data.data.map(item => ({
    text: item.name,
    value: item.id
  }));
};
const getStoresSubType = async () => {
  const { data } = await factoryService.storesSubLists();
  columnsRepositories[Single.STORES_SUB_TYPE] = data.data.map(item => ({
    text: item.stores_sub,
    value: item.id
  }));
};
const getAreaList = async () => {
  const { data } = await mydbService.allRegionLists();
  columnsRepositories[Multiple.AREA] = data.data.map(item => ({
    text: item.name,
    value: item.code,
    parentId: item.pid_code
  }));
};

export function usePicker(type: Single, option?: Option): { picker: Picker<"Single"> };
export function usePicker(type: Multiple, option?: Option): { picker: Picker<"Multiple"> };
export function usePicker(type: Single | Multiple, option: Option = {}): any {
  const flat2tree = () => {
    const arr: PickerColumn[] = columnsRepositories[type];
    arr.forEach(i => {
      const l = arr.filter(c => i.value === c.parentId);
      if (l.length > 0) i.children = l;
    });
    return arr;
  };
  let columns = computed(() => flat2tree());
  switch (type) {
    case Single.CERTIFICATE_TYPE:
      if (columnsRepositories[type].length === 0) getCertificateType();
      break;
    case Single.STORES_SUB_TYPE:
      if (columnsRepositories[type].length === 0) getStoresSubType();
      break;
    case Multiple.AREA:
      if (columnsRepositories[type].length === 0) getAreaList();
      columns = computed(() => flat2tree().filter(i => i.parentId === "0"));
      break;
    default:
      break;
  }
  const picker = reactive<Picker>({
    label: option.defaultLabel || "",
    value: option.defaultValue || "",
    showPicker: false,
    columns,
    onConfirm: values => {
      if (Array.isArray(values)) {
        picker.label = "";
        picker.value = values.map(value => value.value);
        values.forEach(i => {
          picker.label += i.text += " ";
        });
      } else {
        picker.value = values.value;
        picker.label = values.text;
      }
      picker.showPicker = false;
    },
    onCancel: () => {
      picker.showPicker = false;
    },
    onChange: () => {}
  });

  return {
    picker
  };
}

export const PickerType = {
  Single,
  Multiple
};
