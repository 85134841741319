import { api } from "@/utils/axios";

interface ApprovalStartDoBody {
  approval_temp_id: string;
  approval_json_result: string;
  insure_id?: string;
  id?: string;
  approval_token?: string;
  save_temp?: string;
}

export interface ApprovalOptBody {
  approval_token: string;
  approval_id: string;
  type?: number;
  reason?: string;
  approval_json_result?: string;
  user_ids?: string;
  result?: string;
  next_node_id?: string;
  target_node_id?: string;
}

interface ApprovalMyRelationBody {
  state?: string;
  type?: string;
  page?: number;
  pagesize?: number;
  keyword?: string;
  approval_temp_ids?: string;
  start_date?: string;
  end_date?: string;
}
export const approvalService = {
  getProcessLists(approval_id: string) {
    return api.post<ResponseData>("/Approval/approval_process_lists", {
      approval_id
    });
  },
  approvalOpt(body: ApprovalOptBody) {
    return api.post<ResponseData>("/Approval/approval_opt", body);
  },
  getApprovalTempList(scene: string, from?: string) {
    return api.post<ResponseData<Approval.TempList>>("/Approval/approval_temp_lists", {
      scene,
      from
    });
  },
  approvalStart(id: string) {
    return api.post<ResponseData<Approval.TempList>>("/Approval/approval_start", {
      approval_temp_id: id
    });
  },
  approvalStartDo(body: ApprovalStartDoBody) {
    return api.post<ResponseData>("/Approval/approval_start_do", body);
  },
  approvalMyRelation(body?: ApprovalMyRelationBody) {
    return api.post<PageResponseData>("/Approval/approval_lists", body);
  },
  approvalStartSure(id: string, user_ids: string) {
    return api.post<ResponseData>("/Approval/approval_start_sure", {
      id,
      user_ids
    });
  },
  approvalInfo(id: string, is_special?: string) {
    return api.post<ResponseData>("/Approval/approval_info", {
      id,
      is_special
    });
  },
  getApprovalPassLists(approval_id: string | number) {
    return api.post<ResponseData>("/Approval/approval_pass_lists", {
      approval_id
    });
  },
  passPic(id: string) {
    return api.post<ResponseData>("/Approval/approval_pass_pic", {
      approval_pass_id: id
    });
  },
  approvalDel(id: string) {
    return api.post<ResponseData>("/Approval/approval_del", {
      approval_ids: id
    });
  }
};
