
import { computed, defineComponent, PropType, toRefs } from "vue";

export default defineComponent({
  name: "UfReadonlyText",
  props: {
    options: {
      type: Object as PropType<Approval.Show>,
      default: {},
      required: true
    }
  },
  setup(props) {
    const { options } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    return {
      disabled
    };
  }
});
