
import moment from "moment";
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

export default defineComponent({
  name: "UfDate",
  props: {
    options: {
      type: Object as PropType<Approval.Date>,
      default: {},
      required: true
    },
    modelValue: {
      type: String as PropType<string>,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);
    const show = ref(false);
    const disabled = computed(() => options.value.power == 1);

    const handleFiledClick = () => {
      if (disabled.value) return;
      show.value = true;
    };
    const date = ref(modelValue.value ? moment(modelValue.value).toDate() : new Date());

    const minDate = moment("1970-01-01").toDate();
    const maxDate = moment("2070-01-01").toDate();

    const showVal = computed(() => {
      return modelValue.value;
    });
    const onCancel = () => {
      show.value = false;
    };
    const onOK = () => {
      const format = options.value.date_type == "1" ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
      emit("update:modelValue", moment(date.value).format(format));
      show.value = false;
    };
    const onClear = () => {
      emit("update:modelValue", "");
      show.value = false;
    };
    const formatter = (type: string, val: string) => {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      if (type === "day") {
        return `${val}日`;
      }
      if (type === "hour") {
        return `${val}时`;
      }
      if (type === "minute") {
        return `${val}分`;
      }
      return val;
    };
    return {
      show,
      handleFiledClick,
      date,
      showVal,
      disabled,
      onCancel,
      onOK,
      onClear,
      minDate,
      maxDate,
      formatter
    };
  }
});
