
import { hqdbService } from "@/service";
import { computed, defineComponent, PropType, reactive, toRefs } from "vue";
import { UserInfo } from "./Index.vue";

interface Factory {
  id: string;
  level: string;
  lists: UserInfo[];
  owner_name: string;
  factory_name: string;
}

interface State {
  list: Factory[];
  loading: boolean;
  finished: boolean;
  page: number;
  checked: UserInfo[];
  customerChecked: string[];
}

export interface FactoryRef {
  onSearch(searchName: string): void;
}

export default defineComponent({
  name: "Factory",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array as PropType<UserInfo[]>
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { multiple } = toRefs(props);
    const state = reactive<State>({
      list: [],
      loading: false,
      finished: false,
      page: 1,
      checked: [],
      customerChecked: []
    });
    const checkedIdList = computed(() => state.checked.map(item => item.id));
    const onLoad = async (searchName = "") => {
      try {
        const { data } = await hqdbService.subFactoryUser({
          search_name: searchName
        });
        state.list.push(...data.data);
        state.loading = false;
        state.finished = false;
        state.page++;
        if (state.list.length >= data.total) {
          state.finished = true;
        }
      } catch (error) {
        console.log(error);
        state.list = [];
        state.finished = true;
      }
    };
    const onClickTitle = (id: string) => {
      const index = state.customerChecked.findIndex(item => item === id);
      if (index === -1) {
        state.customerChecked.push(id);
      } else {
        state.customerChecked.splice(index, 1);
      }
    };
    const onClick = (user: UserInfo) => {
      if (!multiple.value) {
        state.checked = [user];
      } else {
        const index = state.checked.findIndex(item => item.id === user.id);
        if (index === -1) {
          state.checked.push(user);
        } else {
          state.checked.splice(index, 1);
        }
      }
      emit("update:modelValue", state.checked);
    };
    const onSearch = (value: string) => {
      state.page = 1;
      state.list = [];
      onLoad(value);
    };
    return {
      state,
      onLoad,
      onSearch,
      onClick,
      checkedIdList,
      onClickTitle
    };
  }
});
