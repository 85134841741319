
import { computed, defineComponent, PropType, ref, toRefs } from "vue";

interface ListItem {
  value: number;
  label: string;
}

interface Item {
  toggle(): void;
}

export default defineComponent({
  name: "List",
  props: {
    options: {
      type: Array as PropType<ListItem[]>
    },
    modelValue: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const checkedVal = computed<number[]>({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value ? value : []);
      }
    });
    const checkboxRefs = ref<Item[]>([]);
    const toggle = (index: number) => {
      console.log(",我点击了", index);
      checkboxRefs.value[index].toggle();
    };
    return {
      checkboxRefs,
      checkedVal,
      toggle
    };
  }
});
