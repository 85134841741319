
import { computed, defineComponent, PropType, ref, toRefs, inject } from "vue";
import List from "./List.vue";

export default defineComponent({
  name: "UfRadio",
  props: {
    options: {
      type: Object as PropType<Approval.Radio>,
      default: {},
      required: true
    },
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { options, modelValue } = toRefs(props);
    const isEventDetail = inject("isEventDetail");
    const show = ref(false);
    const checkedVal = ref(null);
    const disabled = computed(() => options.value.power == 1);
    const innerOptions = computed(() => {
      return options.value.option.map(item => ({
        label: item.value,
        value: item.key
      }));
    });
    const value = computed({
      get() {
        const item = innerOptions.value.find(item => {
          return item.value.toString() == modelValue?.value;
        });
        return item ? item.label : "";
      },
      set(value) {
        emit("update:modelValue", value || null);
      }
    });
    const handleFiledClick = () => {
      if (disabled.value) return;
      show.value = true;
    };
    const handleClearVal = () => {
      checkedVal.value = null;
    };
    const handleCancel = () => {
      show.value = false;
    };
    const handleOk = () => {
      value.value = checkedVal.value;
      handleCancel();
    };

    return {
      value,
      show,
      checkedVal,
      handleFiledClick,
      handleClearVal,
      handleCancel,
      handleOk,
      innerOptions,
      disabled,
      isEventDetail
    };
  },
  components: {
    List
  }
});
