
import { useValidator } from "@/composables";
import { Toast, ImagePreview } from "vant";
import { computed, defineComponent, onBeforeUpdate, onMounted, onUpdated, PropType, reactive, ref, toRefs } from "vue";
import { SubFormItemGroup } from "../SubFormItemGroup.vue";

export default defineComponent({
  name: "UfMoreInfo",
  props: {
    options: {
      type: Object as PropType<Approval.Company | Approval.Userinfo>,
      default: {},
      required: true
    },
    id: {
      type: String,
      required: true
    },
    rawType: {
      type: String,
      default: ""
    },
    list: {
      type: Array as PropType<Approval.SubComponents[]>,
      required: true
    }
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const { rawType, options, list } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    // 子组件ref
    const itemRefs = ref<SubFormItemGroup[]>([]);
    onBeforeUpdate(() => {
      itemRefs.value = [];
    });
    const value = ref("");
    // 显示
    const popupShow = ref(false);
    const onClick = () => {
      if (disabled.value) return;
      popupShow.value = true;
    };
    const init = () => {
      const values: any[] = [];
      itemRefs.value.forEach(item => {
        item.submit(value => {
          values.push(value);
        });
      });
      value.value = values[0][itemRefs.value[0].getItemIdByRawType(rawType.value)];
      emit("confirm", values);
    };
    onMounted(() => {
      init();
    });
    onUpdated(() => {
      init();
    });
    const confirm = () => {
      const values: any[] = [];
      itemRefs.value.forEach(item => {
        item.submit(value => {
          values.push(value);
        });
      });
      // 暂时一定只有一个内容
      value.value = values[0][itemRefs.value[0].getItemIdByRawType(rawType.value)];
      const find = useValidator(list.value[0], values[0]);
      if (find) {
        Toast.fail(find.message);
        return;
      }
      emit("confirm", values);
      popupShow.value = false;
    };

    const show = ref(false);
    const active: any = reactive({
      photo_type: "",
      photo_file: ""
    });
    const actions = computed(() => {
      if (["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].includes(active.photo_type || ""))
        return [
          { name: "预览", code: "0" },
          { name: "下载", code: "1" }
        ];
      return [{ name: "下载", code: "1" }];
    });

    const onSelect = (action: any) => {
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `/pages/download/main?url=${active.photo_file}&type=${active.photo_type}&action=${action.code}`
      });
    };
    const download = (file: any) => {
      if (!disabled.value) return;
      if (["jpg", "jpeg", "png", "gif"].includes(file.photo_type || "")) {
        ImagePreview([file.photo_file || ""]);
        return;
      }
      Object.assign(active, file);
      show.value = true;
    };
    return {
      itemRefs,
      confirm,
      popupShow,
      onClick,
      value,
      disabled,
      ImagePreview,
      download,
      onSelect,
      show,
      actions
    };
  }
});
