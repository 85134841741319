<template>
  <div class="uf-form-item">
    <label class="uf-form-item__label" :class="{ 'uf-form-item__label--readonly': readonly }">
      {{ title }} <i v-if="required">*</i>
      <span style="float: right" v-if="leftContent">{{ leftContent }}</span>
    </label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "uf-form-item",
  props: {
    title: String,
    leftContent: String,
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="less">
.uf-form-item__label--readonly {
  color: #c8c9cc !important;
}
.uf-approvalDetail {
  .uf-form-item__label--readonly {
    color: #2d333e !important;
  }
  .van-field__control:disabled {
    -webkit-text-fill-color: #2d333e;
  }
}
</style>
