import validate from "@/utils/validate";
type Data = {
  [key: string]: string | Data[];
};
type ValidatorError =
  | {
      message: string;
      component: Approval.SubComponent;
    }
  | undefined;
export function useValidator(origin: Approval.SubComponents, data: Data) {
  const vaildate = (item: Approval.SubComponent, value: string, parent?: Approval.SubComponent): ValidatorError => {
    if (value) {
      if (item.type === "check_money") {
        if (item.num_max && Number(value) > Number(item.num_max)) {
          return {
            message: item.title + "不能大于" + item.num_max,
            component: item
          };
        }
      }
      if (item.require == "id_card_number") {
        if (validate.isCardID(value)) return;
        return {
          message: "请输入正确的身份证号",
          component: item
        };
      } else if (item.require == "mobile") {
        if (validate.test("mobile", value)) return;
        return {
          message: "请输入正确的手机号",
          component: item
        };
      } else if (item.require == "email") {
        if (validate.test("email", value)) return;
        return {
          message: "请输入正确的邮箱",
          component: item
        };
      } else if (item.require == "credit_code") {
        if (validate.test("creditCode", value)) return;
        return {
          message: "请输入正确的统一社会信用代码",
          component: item
        };
      }
    }
    if (item.is_checked == "0") return;
    if (!value) {
      let prefix = "请输入";
      const isUpload = ["check_pic"].includes(item.type);
      switch (true) {
        case [
          "check_date",
          "check_relation",
          "check_region",
          "check_user",
          "check_dateslot",
          "check_radio",
          "check_checkbox",
          "check_place"
        ].includes(item.type):
          prefix = "请选择";
          break;
        case ["check_pic", "check_file"].includes(item.type):
          prefix = "请上传";
          break;
        case ["check_nvq", "check_assign"].includes(item.type):
          prefix = "请完善";
          break;
        default:
          break;
      }
      return {
        message: `${prefix}${parent ? `${parent.title}的` : ""}${item.title}`,
        component: item
      };
    }
  };
  const deepMap = (list: Approval.SubComponents, data: Data, parent?: Approval.SubComponent): ValidatorError => {
    let find: ValidatorError;
    for (const item of list) {
      const value = data[item.id];
      if (item.lists && Array.isArray(value)) {
        for (const index in item.lists) {
          find = deepMap(item.lists[index], value[index] || {}, item);
          if (find) break;
        }
        if (find) break;
      }
      const pass = vaildate(item, value as string, parent);
      if (pass) {
        find = pass;
        break;
      }
    }
    return find;
  };
  const find = deepMap(origin, data);
  return find;
}
