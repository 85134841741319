
import { hqdbService } from "@/service";
import { computed, defineComponent, PropType, reactive, toRefs, watch } from "vue";
import { UserInfo } from "./Index.vue";
interface State {
  list: UserInfo[];
  loading: boolean;
  finished: boolean;
  page: number;
  checked: UserInfo[];
}
export interface StaffRef {
  onSearch(searchName: string): void;
}
export default defineComponent({
  name: "Staff",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array as PropType<UserInfo[]>,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { multiple, modelValue } = toRefs(props);
    const state = reactive<State>({
      list: [],
      loading: false,
      finished: false,
      page: 1,
      checked: []
    });
    watch(
      () => modelValue.value,
      now => {
        if (now.length == 0) {
          state.checked = [];
        }
      }
    );
    const checkedIdList = computed(() => state.checked.map(item => item.id));
    const onLoad = async (searchName = "") => {
      try {
        const { data } = await hqdbService.subUserLists({
          user_type: "1",
          page: state.page,
          search_name: searchName,
          need_zong: "1"
        });
        state.list.push(...data.data);
        state.loading = false;
        state.finished = false;
        state.page++;
        if (state.list.length >= data.total) {
          state.finished = true;
        }
      } catch (error) {
        console.log(error);
        state.list = [];
        state.finished = true;
      }
    };
    const onClick = (user: UserInfo) => {
      if (!multiple.value) {
        state.checked = [user];
      } else {
        const index = state.checked.findIndex(item => item.id === user.id);
        if (index === -1) {
          state.checked.push(user);
        } else {
          state.checked.splice(index, 1);
        }
      }
      emit("update:modelValue", state.checked);
    };
    const onSearch = (value: string) => {
      state.page = 1;
      state.list = [];
      onLoad(value);
    };
    return {
      state,
      onLoad,
      onClick,
      checkedIdList,
      onSearch
    };
  }
});
