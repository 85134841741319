import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")
  const _component_choose_person = _resolveComponent("choose-person")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_uf_form_item, {
      title: _ctx.options.title,
      required: _ctx.options.is_checked == '1',
      readonly: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, _mergeProps({
          placeholder: "请选择",
          modelValue: _ctx.fieldValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.fieldValue = $event)),
          readonly: "",
          disabled: _ctx.disabled
        }, _ctx.$attrs, {
          "is-link": !_ctx.disabled,
          onClick: _ctx.onClick
        }), null, 16, ["modelValue", "disabled", "is-link", "onClick"])
      ]),
      _: 1
    }, 8, ["title", "required", "readonly"]),
    _createVNode(_component_choose_person, {
      show: _ctx.choosePersonShow,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.choosePersonShow = $event)),
      multiple: !_ctx.options.is_radio,
      onConfirm: _ctx.onConfirm
    }, null, 8, ["show", "multiple", "onConfirm"])
  ], 64))
}