import { createStore, useStore as baseUseStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { InjectionKey } from "vue";
import { userService } from "@/service";
import { State } from "./index.interface";

export const key: InjectionKey<Store<State>> = Symbol("base");
export const store = createStore<State>({
  plugins: [
    createPersistedState({
      reducer(state: State) {
        return {
          token: state.token
        };
      }
    })
  ],
  state: {
    token: undefined,
    userInfo: null
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setToken(state, token: string) {
      state.token = token;
    }
  },
  getters: {
    userInfo: state => state.userInfo
  },
  actions: {
    setPower({ commit }, list) {
      commit("setPower", list);
    },
    async getUserInfo({ commit }) {
      try {
        const { data } = await userService.getUserInfo();
        commit("setUserInfo", data.data);
      } catch (error) {
        commit("setToken", "");
      }
    }
  },
  modules: {}
});

export function useStore() {
  return baseUseStore(key);
}
