import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")

  return (_openBlock(), _createBlock(_component_uf_form_item, {
    title: _ctx.options.title,
    required: _ctx.options.is_checked == '1',
    readonly: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_field, _mergeProps({
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.value = $event)),
        autosize: true,
        type: "textarea",
        "show-word-limit": "",
        maxlength: _ctx.options.word_num,
        placeholder: _ctx.isEventDetail ? '-' : '请输入',
        disabled: _ctx.disabled
      }, _ctx.$attrs), null, 16, ["modelValue", "maxlength", "placeholder", "disabled"])
    ]),
    _: 1
  }, 8, ["title", "required", "readonly"]))
}