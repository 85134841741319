import { api } from "@/utils/axios";

interface SubUserListsBody {
  user_type: string;
  page?: number;
  search_name?: string;
  need_zong?: string;
}
interface SubCustomerBody {
  search_name?: string;
}
interface SubFactoryUserBody {
  search_name?: string;
}

export const hqdbService = {
  // departmentUserLists() {
  //   return api.post<ResponseData>("/Hqdb/department_user_lists");
  // },
  subDepartmentLists(body: any) {
    return api.post<ResponseData>("/Hqdb/sub_department_lists", body);
  },
  subUserLists(body: SubUserListsBody) {
    return api.post<PageResponseData>("/Hqdb/sub_user_lists", body);
  },
  subCustomer(body: SubCustomerBody) {
    return api.post<PageResponseData>("/Hqdb/sub_customer", body);
  },
  subFactoryUser(body: SubFactoryUserBody) {
    return api.post<PageResponseData>("/Hqdb/sub_factory_user", body);
  }
};
