
import { computed, defineComponent, ref, PropType, toRefs, provide, onBeforeUpdate } from "vue";
import { hqdbService } from "@/service";
import UfTree, { UfTreeType, DepartmentTreeNode } from "./Tree.vue";
import { Toast } from "vant";

export default defineComponent({
  name: "UfDepartment",
  components: {
    UfTree
  },
  props: {
    data: {
      type: Array as PropType<DepartmentTreeNode[]>,
      required: false
    },
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:show", "confirm"],
  setup(props, { emit }) {
    // popup
    const { show, multiple } = toRefs(props);

    provide("multiple", multiple.value);

    const popupShow = computed({
      get() {
        return show.value;
      },
      set(value) {
        emit("update:show", value);
      }
    });
    // 搜索内容
    const value = ref("");
    // 获取数据的
    const departmentStore = ref<DepartmentTreeNode[]>([]);
    const getDepartment = async (searchName = "") => {
      try {
        const { data } = await hqdbService.subDepartmentLists({
          search_name: searchName
        });
        departmentStore.value = data.data;
      } catch (error) {
        departmentStore.value = [];
        // state.finished = true;
      }
    };
    getDepartment();
    // 子组件拿值
    const ufTreeRef = ref<UfTreeType>();
    const getPlaceInfo = () => {
      const info = ufTreeRef.value?.getCurrentNode();
      if (Array.isArray(info) && info.length === 0) {
        Toast.fail("请选择一个");
        return;
      }
      if (!info || Object.keys(info).length === 0) {
        Toast.fail("请选择一个");
        return;
      }
      emit("confirm", info);
      popupShow.value = false;
    };
    const onSearch = (val: string) => {
      getDepartment(val);
    };

    const checkedPlacePopover = ref<boolean[]>([]);
    const checkedPlaceRef = ref<any[]>([]);
    onBeforeUpdate(() => {
      checkedPlaceRef.value = [];
    });
    const checkedList = computed(() => {
      ufTreeRef.value?.checkedList.forEach(item => {
        (item as any).showPopover = false;
      });
      return ufTreeRef.value?.checkedList || [];
    });
    const showCheckedList = ref(false);

    const addCheckedPlaceRef = async (el: Element, index: number) => {
      checkedPlaceRef.value[index] = el;
    };

    const opened = () => {
      checkedPlaceRef.value.forEach((el, index) => {
        checkedPlacePopover.value[index] = el.getBoundingClientRect().right > window.innerWidth - 30;
      });
    };
    const clear = () => {
      ufTreeRef.value?.checkedList.splice(0);
      showCheckedList.value = false;
    };

    return {
      popupShow,
      value,
      departmentStore,
      getPlaceInfo,
      ufTreeRef,
      onSearch,
      checkedList,
      showCheckedList,
      addCheckedPlaceRef,
      checkedPlacePopover,
      clear,
      opened
    };
  }
});
