import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "uf-staff-selector" }
const _hoisted_2 = { class: "uf-staff-selector__inner" }
const _hoisted_3 = {
  key: 0,
  style: {"margin-right":"10px"}
}
const _hoisted_4 = {
  key: 2,
  class: "uf-staff-selector__inner-avatar2"
}
const _hoisted_5 = { class: "uf-staff-selector__info" }
const _hoisted_6 = { class: "uf-staff-selector__info-name" }
const _hoisted_7 = { class: "uf-staff-selector__info-other" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_list = _resolveComponent("van-list")

  return (_openBlock(), _createBlock(_component_van_list, {
    loading: _ctx.state.loading,
    "onUpdate:loading": _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.loading = $event)),
    finished: _ctx.state.finished,
    "finished-text": "没有更多了",
    onLoad: _ctx.onLoad
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.state.list, (user) => {
          return (_openBlock(), _createBlock("div", {
            class: ["uf-staff-selector__item", {
          'uf-staff-selector__item--active': _ctx.checkedIdList.includes(user.id)
        }],
            key: user.id,
            onClick: ($event: any) => (_ctx.onClick(user))
          }, [
            _createVNode("div", _hoisted_2, [
              (_ctx.multiple)
                ? (_openBlock(), _createBlock("div", _hoisted_3, [
                    _withDirectives(_createVNode(_component_van_icon, {
                      name: "circle",
                      color: "#CCCCCC"
                    }, null, 512), [
                      [_vShow, !_ctx.checkedIdList.includes(user.id)]
                    ]),
                    _withDirectives(_createVNode(_component_van_icon, {
                      name: "checked",
                      color: "#0065FF "
                    }, null, 512), [
                      [_vShow, _ctx.checkedIdList.includes(user.id)]
                    ])
                  ]))
                : _createCommentVNode("", true),
              (user.head_pic)
                ? (_openBlock(), _createBlock(_component_van_image, {
                    key: 1,
                    round: "",
                    width: "40px",
                    height: "40px",
                    src: user.head_pic,
                    class: "uf-staff-selector__inner-avatar"
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true),
              (!user.head_pic)
                ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(user.name[0]), 1))
                : _createCommentVNode("", true),
              _createVNode("div", _hoisted_5, [
                _createVNode("div", _hoisted_6, _toDisplayString(user.name), 1),
                _createVNode("div", _hoisted_7, _toDisplayString(user.department_name) + " - " + _toDisplayString(user.duty_name), 1)
              ])
            ])
          ], 10, ["onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"]))
}