
import { defineComponent, PropType, computed, toRefs } from "vue";

export default defineComponent({
  name: "UfTextField",
  props: {
    options: {
      type: Object as PropType<Approval.Text>,
      default: {},
      required: true
    },
    modelValue: {
      type: String as PropType<string>,
      default: ""
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, options } = toRefs(props);
    const disabled = computed(() => options.value.power == 1);
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(val) {
        emit("update:modelValue", val);
      }
    });
    return {
      value,
      disabled
    };
  }
});
