
import SignaturePad from "signature_pad";
import { rotateBase64 } from "@/utils/util";
import { computed, defineComponent, nextTick, onMounted, ref, toRefs } from "vue";
import { Toast } from "vant";
import { mydbService } from "@/service";

export default defineComponent({
  name: "UfSignature",
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ["update:show", "confirm"],
  setup(props, { emit }) {
    const { show } = toRefs(props);
    const popupShow = computed({
      get() {
        return show.value;
      },
      set(value) {
        emit("update:show", value);
      }
    });
    const signature = ref<HTMLCanvasElement | null>(null);
    let signaturePad: SignaturePad | null = null;
    const overlayShow = ref(false);
    const orientation = ref<string | number>(0);
    let canvas: HTMLCanvasElement;
    let ctx: CanvasRenderingContext2D | null = null;
    const onorientationchange = async (origin: string | Event) => {
      await nextTick();
      if (!ctx) return;
      orientation.value = window.orientation;
      const ratio = 1;
      const width = canvas.offsetWidth * ratio;
      const height = canvas.offsetHeight * ratio;
      if (origin === "onOpen") {
        canvas.width = width;
        canvas.height = height;
      } else {
        if (canvas.width == width) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }
      }
      ctx.scale(ratio, ratio);
      signaturePad?.clear();
    };
    const initCanvas = () => {
      canvas = signature.value as HTMLCanvasElement;
      ctx = canvas.getContext("2d");
      signaturePad = new SignaturePad(canvas, {
        backgroundColor: "#ffffff"
      });
    };

    const clear = () => {
      signaturePad?.clear();
    };

    const submit = async () => {
      try {
        if (!signaturePad) return Toast.fail("发生异常");
        if (signaturePad.isEmpty()) {
          emit("confirm", {
            id: "",
            img_url: ""
          });
          popupShow.value = false;
          return;
        }
        overlayShow.value = true;
        const url = await rotateBase64(signaturePad.toDataURL("image/jpeg"));
        const { data } = await mydbService.save_imgflow(url);
        emit("confirm", data.data[0]);
        popupShow.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        overlayShow.value = false;
      }
    };
    onMounted(() => {
      initCanvas();
    });
    const onOpen = async () => {
      window.addEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        onorientationchange,
        false
      );
      onorientationchange("onOpen");
    };

    const onClose = () => {
      window.removeEventListener(
        "onorientationchange" in window ? "orientationchange" : "resize",
        onorientationchange,
        false
      );
    };

    return {
      clear,
      submit,
      overlayShow,
      orientation,
      popupShow,
      signature,
      onOpen,
      onClose
    };
  }
});
