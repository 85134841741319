
import { computed, defineComponent, reactive, ref, toRaw, toRefs, watch } from "vue";
import StaffComponent, { StaffRef } from "./Staff.vue";
import Customer, { CustomerRef } from "./Customer.vue";
import Factory, { FactoryRef } from "./Factory.vue";
import { Toast } from "vant";
export type UserInfo = {
  id: string;
  head_pic: string;
  name: string;
  department_name: string;
  duty_name: string;
};
export default defineComponent({
  name: "ChoosePerson",
  components: {
    Staff: StaffComponent,
    Customer,
    Factory
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: ["confirm", "update:show"],
  setup(props, { emit }) {
    const { show, multiple } = toRefs(props);
    const popupShow = computed({
      get() {
        return show.value;
      },
      set(value) {
        emit("update:show", value);
      }
    });
    const state = reactive<Dictionary<UserInfo[]>>({
      staff: [],
      customer: [],
      factory: []
    });
    watch(
      () => state.staff,
      now => {
        if (multiple.value || now.length == 0) return;
        state.customer = [];
      }
    );
    watch(
      () => state.customer,
      now => {
        if (multiple.value || now.length == 0) return;
        state.staff = [];
      }
    );

    const value = ref("");
    const active = ref("staff");
    const confirm = () => {
      const user_ids = Object.values(toRaw(state)).flat();
      if (user_ids.length === 0) return Toast.fail("请选择人员");
      popupShow.value = false;
      emit("confirm", toRaw(state), user_ids.map(item => item.id).join(","));
    };

    // search
    const search_value = ref("");
    const search_employees = ref<StaffRef | null>(null);
    const search_customer = ref<CustomerRef | null>(null);
    const search_factory = ref<FactoryRef | null>(null);

    const onSearch = (val: string) => {
      if (active.value == "staff") {
        search_employees.value?.onSearch(val);
      } else if (active.value == "customer") {
        search_customer.value?.onSearch(val);
      } else if (active.value == "factory") {
        search_factory.value?.onSearch(val);
      }
    };

    // change tab
    const changeTab = () => {
      search_value.value = "";
      onSearch("");
    };
    return {
      search_value,
      search_employees,
      search_customer,
      search_factory,
      onSearch,
      value,
      popupShow,
      active,
      confirm,
      state,
      changeTab
    };
  }
});
