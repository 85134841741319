import { api } from "@/utils/axios";
import { store } from "@/store";

export interface AddRemarks {
  id: string;
  close_result_one: string;
  close_result_two?: string;
  result_desc?: string;
  pic_ids?: string;
  opt_content?: string;
}
export const fireService = {
  fireLists(page: number, pagesize: number) {
    return api.post<PageResponseData<any[]>>("/Fire_control/fire_lists", {
      page,
      pagesize,
      order: "1",
      copy_user_id: store.state.userInfo?.id
    });
  },
  fireDetail(id: string) {
    return api.post<ResponseData>("/Fire_control/fire_detail", {
      id,
      get_log: "1",
      button: "1"
    });
  },
  fireLog(id: string) {
    return api.post<ResponseData>("/Fire_control/fire_log", {
      id
    });
  },
  fireResult(state = "0", level = "1") {
    return api.post<ResponseData>("/Fire_control/fire_result", {
      state,
      level
    });
  },
  addRemarks(body: AddRemarks) {
    return api.post<ResponseData>("/Fire_control/add_remarks", body);
  }
};
