
import { defineComponent, onBeforeUpdate, PropType, provide, reactive, toRefs } from "vue";
import { useTempFormData } from "@/composables";
import { PlaceTreeNode } from "@/components/location/Tree.vue";
import _ from "lodash";

export interface SubFormItemGroup {
  submit(cb?: (value: any, id: string) => void): void;

  getItemIdByRawType(type: string): string;
}

export default defineComponent({
  name: "SubFormItemGroup",
  props: {
    isEventDetail: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    list: {
      type: Array as PropType<Approval.SubComponents>,
      required: true
    }
  },
  setup(props) {
    const { isEventDetail } = toRefs(props);
    provide("isEventDetail", isEventDetail);
    const componentsType = reactive({
      check_show: "uf-readonly-text",
      check_radio: "uf-radio",
      check_checkbox: "uf-checkbox",
      check_text: "uf-text-field",
      check_textarea: "uf-textarea",
      check_number: "uf-number",
      check_money: "uf-amount",
      check_dateslot: "uf-date-slot",
      check_date: "uf-date",
      check_pic: "uf-image",
      check_id_photo: "uf-id-photo",
      check_file: "uf-enclosure",
      check_region: "uf-region",
      check_assign: "uf-sign",
      check_user: "uf-user",
      check_relation: "uf-relation",
      check_stores: "uf-check-stores"
    });
    const { list, id: parentId } = toRefs(props);
    // 返回响应式数据
    const { formData, getFormattedData } = useTempFormData(list.value);
    // 格式化选项数据
    const formatOption = (option: Approval.Option[]) => {
      return option.map(item => ({
        label: item.value,
        value: item.key
      }));
    };
    // 详情组件相关
    const addDetail = (list: Approval.SubComponents[]) => {
      const o = _.cloneDeepWith(list[0], (value, key) => {
        if (key === "result") return "";
        if (key === "pic_arr") return [];
        if (key === "nvq_arr") return [];
      });
      o[0].shortId = Date.now();
      list.push(o);
    };
    const removeDetail = (list: Approval.SubComponents[], index: number) => {
      list.splice(index, 1);
    };
    // 子组件ref
    let itemRefs: SubFormItemGroup[] = [];
    const setItemRef = (el: SubFormItemGroup) => {
      if (el) itemRefs.push(el);
    };
    onBeforeUpdate(() => {
      itemRefs = [];
    });
    // 组件
    const setFormDataValue = (data: PlaceTreeNode, id: string) => {
      formData[id] = data;
    };
    // 验证

    // 通过传入原始类型，找到对应的字段ID是什么。
    const getItemIdByRawType = (type: string) => {
      const find = list.value.find(item => item?.require === type);
      return find?.id || "";
    };
    // 提交
    const submit: SubFormItemGroup["submit"] = callback => {
      const formattedData = getFormattedData();
      itemRefs.forEach(item => {
        item.submit((value, id) => {
          formattedData[id].push(value);
        });
      });
      callback?.(formattedData, parentId.value);
    };
    const getDateilMerge = (item: Approval.Detail) => {
      const o: Dictionary<{ title: string; total: number }> = {};
      item.lists.forEach(list => {
        list.forEach(i => {
          if (i.type == "check_number" || i.type == "check_money") {
            if (o[i.id]) {
              o[i.id].total += Number(i.result);
            } else {
              o[i.id] = {
                title: i.title,
                total: Number(i.result)
              };
            }
          }
        });
      });
      return Object.values(o).reduce((p, n) => {
        return `${p}${n.title}:${n.total}, `;
      }, "");
    };
    return {
      submit,
      formData,
      formatOption,
      setItemRef,
      setFormDataValue,
      getItemIdByRawType,
      addDetail,
      removeDetail,
      componentsType,
      getDateilMerge
    };
  }
});
