
import { computed, defineComponent, PropType, reactive, ref, toRefs } from "vue";
import { useUpload, UfUploadFileType } from "@/composables";
import { ImagePreview, Toast } from "vant";

export default defineComponent({
  name: "UfUpload",
  props: {
    accept: {
      type: String,
      default: ""
    },
    maxCount: {
      type: String,
      default: ""
    },
    tipText: String,
    disabled: Boolean,
    maxSize: {
      type: [String, Number]
    },
    modelValue: {
      type: Array as PropType<UfUploadFileType[]>,
      required: true
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, disabled } = toRefs(props);
    const { upload, removeUploaded } = useUpload();
    const fileList = computed<UfUploadFileType[]>({
      get() {
        return modelValue.value;
      },
      set(value) {
        emit("update:modelValue", value);
      }
    });
    const show = ref(false);
    const active: any = reactive({});
    const actions = computed(() => {
      if (["doc", "docx", "xls", "xlsx", "ppt", "pptx", "pdf"].includes(active.res?.photo_type || ""))
        return [
          { name: "预览", code: "0" },
          { name: "下载", code: "1" }
        ];
      return [{ name: "下载", code: "1" }];
    });

    const onSelect = (action: any) => {
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `/pages/download/main?url=${active.res.photo_file}&type=${active.res.photo_type}&action=${action.code}`
      });
    };
    const download = (file: UfUploadFileType) => {
      // if (disabled.value) return;
      if (file.res.file_type) {
        file.res.photo_type = file.res.file_type;
        file.res.photo_file = file.res.img_url;
      }
      if (["jpg", "jpeg", "png", "gif"].includes(file.res.photo_type || "")) {
        ImagePreview([file.res?.photo_file || ""]);
        return;
      }
      Object.assign(active, file);
      show.value = true;
    };

    const onOversize = () => {
      Toast("文件大小不能超过20MB");
    };

    return {
      fileList,
      upload,
      removeUploaded,
      download,
      show,
      actions,
      onSelect,
      onOversize
    };
  }
});
