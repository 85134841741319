import { api } from "@/utils/axios";
import { store } from "@/store";
export interface AddNewEventBody {
  event_type: string;
  event_json?: string;
  department_id?: string;
}
export interface ExanubeEventBody {
  id: string;
  state: string;
  desc: string;
}
export interface AddPraiseBody {
  id: string;
  praise_notes: string;
  serve_result: string;
  speed_result: string;
  professional_result: string;
  praise_pic: string;
}
export const eventsService = {
  eventTypeDetail(id: string) {
    return api.post<ResponseData>("/Event/event_type_detail", {
      id
    });
  },
  eventLists(page: number, pagesize: number) {
    return api.post<PageResponseData<any[]>>("/Event/event_lists", {
      page,
      pagesize,
      order: "1",
      // not_event_ids: "2,4,3,51",
      event_state: "10,20,30,40,50",
      submit_user_id: store.state.userInfo?.id
    });
  },
  addNewEvent(body: AddNewEventBody) {
    return api.post<ResponseData>("Event/add_new_event", body);
  },
  eventDetail(id: string) {
    return api.post<ResponseData>("Event/event_detail", {
      id,
      button: "1",
      check_power: "1",
      get_log: "1"
    });
  },
  cancelEvent(id: string) {
    return api.post<ResponseData>("/Event/cancel_event", {
      id
    });
  },
  exanubeEvent(body: ExanubeEventBody) {
    return api.post<ResponseData>("/Event/exanube_event", body);
  },
  addPraise(body: AddPraiseBody) {
    return api.post<ResponseData>("/Event/add_praise", body);
  },
  eventLog(id: string) {
    return api.post<ResponseData>("Event/event_log", {
      id
    });
  }
};
