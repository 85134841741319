import { App } from "vue";
import IdcardUpload from "./IdcardUpload.vue";
import Uploader from "./Uploader.vue";
import DaterangePicker from "./DaterangePicker.vue";
import Form from "./form/Form.vue";
import FormItem from "./form/FormItem.vue";
import ChoosePerson from "./choose-person/Index.vue";
import Signature from "./Signature.vue";
import SelectApprove from "./SelectApprove.vue";
import SelectRelation from "./relation/Relation.vue";
import ListItem from "./list/ListItem.vue";
import SubFormItemGroup from "./check/SubFormItemGroup.vue";

import Popup from "./Popup.vue";
import Location from "./location/Location.vue";
import Department from "./department/Department.vue";
import ReadonlyText from "./check/readonly-text/Index.vue";
import Radio from "./check/radio/Index.vue";
import Checkbox from "./check/checkbox/Index.vue";
import TextField from "./check/text-field/Index.vue";
import Textarea from "./check/textarea/Index.vue";
import Number from "./check/number/Index.vue";
import Amount from "./check/amount/Index.vue";
import UfDateSlot from "./check/date-slot/Index.vue";
import UfDate from "./check/date/Index.vue";
import UImage from "./check/image/Index.vue";
import Enclosure from "./check/enclosure/Index.vue";
import Place from "./check/place/Index.vue";
import MoreInfo from "./check/more-info/Index.vue";
import Region from "./check/region/Index.vue";
import IDPhoto from "./check/id-photo/Index.vue";
import Nvq from "./check/nvq/Index.vue";
import User from "./check/user/Index.vue";
import Sign from "./check/signature/Index.vue";
import Relation from "./check/relation/Index.vue";
import Stores from "./Stores.vue";
import CheckStores from "./check/stores/Index.vue";

const components = [
  IdcardUpload,
  Uploader,
  DaterangePicker,
  Form,
  FormItem,
  ChoosePerson,
  SubFormItemGroup,
  Popup,
  Location,
  ReadonlyText,
  Radio,
  Checkbox,
  TextField,
  Textarea,
  Number,
  Amount,
  UfDateSlot,
  UfDate,
  UImage,
  Enclosure,
  Place,
  MoreInfo,
  Region,
  IDPhoto,
  Nvq,
  SelectApprove,
  User,
  Signature,
  Sign,
  Relation,
  SelectRelation,
  ListItem,
  Stores,
  CheckStores,
  Department
];
export default {
  install(app: App<Element>) {
    components.forEach(item => {
      app.component(item.name, item);
    });
  }
};
