import { api } from "@/utils/axios";
import detailsVue from "@/views/visitor/details.vue";

export const alarmService = {
  alarmLists(body: any) {
    return api.post<ResponseData<[]>>("/Alarm/alarm_lists", body);
  },
  detail(body: any) {
    return api.post<ResponseData>("/Alarm/alarm_detail", body);
  }
};
