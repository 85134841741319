
import { computed, defineComponent, toRefs } from "vue";
import ListItemTag from "./ListItemTag.vue";
export default defineComponent({
  name: "listItem",
  props: {
    code: String,
    userName: String,
    title: String,
    content: String,
    footer: String,
    checked: {
      type: Boolean
    },
    type: String,
    typeName: String,
    selectable: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:checked", "on-toggle"],
  setup(props, { emit }) {
    const { checked, selectable } = toRefs(props);
    const value = computed({
      get() {
        return checked.value;
      },
      set(value) {
        emit("update:checked", value);
      }
    });
    const classes = computed(() => {
      return {
        ListItem: true,
        "ListItem--checked": value.value
      };
    });
    const onToggle = () => {
      if (!selectable.value) return;
      value.value = !value.value;
      emit("on-toggle", value.value);
    };
    return {
      value,
      onToggle,
      classes
    };
  },

  components: {
    ListItemTag
  }
});
