import { api } from "@/utils/axios";

export interface SaveImgBody {
  my_files: File;
  is_file?: string;
  is_form?: string;
  is_uf?: string;
}

export interface AreaData {
  code: string;
  name: string;
  pid_code: string;
}

export interface GetPlaceListBody {
  pid?: string;
  is_truename?: string;
  search_name?: string;
}

export const mydbService = {
  save_imgflow(imgdata: string) {
    return api.post<ResponseData>("/Mydb/save_imgflow", {
      imgdata
    });
  },
  saveImg(body: SaveImgBody, progress?: (progress: number) => void) {
    const formData = new FormData();
    Object.keys(body).forEach(key => {
      formData.append(key, body[key]);
    });
    return api.post<ResponseData>("/Mydb/save_img", formData, {
      onUploadProgress(progressEvent) {
        const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
        progress && progress(complete);
      }
    });
  },
  // 接口文档没有，单独新增的获取所有的地址
  allRegionLists() {
    return api.post<ResponseData<AreaData[]>>("/Mydb/all_region_lists");
  },
  getPlaceList(body: GetPlaceListBody = {}) {
    return api.post<ResponseData>("/Mydb/place_lists", body);
  },
  // 识别身份证
  idcardDistinguish(file: File) {
    const formData = new FormData();
    formData.append("my_files", file);
    return api.post<ResponseData>("/Mydb/idcard_distinguish", formData);
  }
};
