
import { approvalService } from "@/service";
import moment from "moment";
import { computed, defineComponent, PropType, reactive, ref, toRefs, watch } from "vue";
export default defineComponent({
  name: "UfSelectRelation",
  props: {
    radio: Boolean,
    show: {
      type: Boolean,
      required: true
    },
    default: {
      type: Array as PropType<Approval.RelationItem[]>,
      default: () => []
    }
  },
  emits: ["update:show", "confirm"],
  setup(props, { emit }) {
    // popup
    const { show, default: def, radio } = toRefs(props);

    const popupShow = computed({
      get() {
        return show.value;
      },
      set(value) {
        emit("update:show", value);
      }
    });

    const searchValue = ref("");
    const list = ref<Approval.RelationItem[]>([]);
    const allChecked = ref<Approval.RelationItem[]>(def.value);
    const onOpen = () => {
      allChecked.value = def.value;
      list.value.forEach(item => {
        item.checked = allChecked.value.some(i => i.id == item.id);
      });
    };
    const onToggle = (checked: boolean, item: any) => {
      if (!radio.value) return;
      list.value.forEach(item => {
        item.checked = false;
      });
      item.checked = !checked;
    };
    watch(
      () => list.value,
      now => {
        allChecked.value.forEach(item => {
          const find = now.find(i => i.id == item.id);
          if (find) {
            item.checked = find.checked;
          }
        });
        now.forEach(item => {
          if (item.checked && !allChecked.value.some(i => i.id == item.id)) {
            allChecked.value.push(item);
          }
        });
        allChecked.value = allChecked.value.filter(item => item.checked);
      },
      { deep: true }
    );
    const dropdownMenuInfo = reactive({
      state: {
        value: "0",
        option: [
          { text: "全部", value: "0" },
          { text: "审批中", value: "1" },
          { text: "审批通过", value: "2" },
          { text: "审批未完成", value: "3" }
        ]
      },
      temp: {
        value: "",
        option: [{ text: "审批模板", value: "" }]
      },
      date: {
        show: false,
        value: []
      }
    });
    const formatDropdownMenuDateValue = computed(() => {
      return dropdownMenuInfo.date.value.map(item => moment(item).format("YYYY.MM.DD")).join(" - ");
    });
    const getApprovalTempList = async () => {
      try {
        const { data } = await approvalService.getApprovalTempList("9", "0");

        dropdownMenuInfo.temp.option.push(
          ...data.data.map(item => ({
            text: item.name,
            value: item.id,
            icon: item.icon
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };

    getApprovalTempList();

    const getApprovalMyRelation = async () => {
      try {
        let start_date = "",
          end_date = "";
        if (dropdownMenuInfo.date.value.length == 2) {
          start_date = moment(dropdownMenuInfo.date.value[0]).format("YYYY.MM.DD");
          end_date = moment(dropdownMenuInfo.date.value[1]).format("YYYY.MM.DD");
        }
        const { data } = await approvalService.approvalMyRelation({
          type: "3",
          page: 1,
          pagesize: 99999,
          keyword: searchValue.value,
          state: dropdownMenuInfo.state.value,
          approval_temp_ids: dropdownMenuInfo.temp.value,
          start_date,
          end_date
        });
        list.value = data.data;
        list.value.forEach(item => {
          item.checked = allChecked.value.some(i => i.id == item.id);
        });
      } catch (error) {
        list.value = [];
      }
    };
    getApprovalMyRelation();
    const onSearch = () => {
      getApprovalMyRelation();
    };
    const dropdownMenuItemValueChange = () => {
      getApprovalMyRelation();
    };
    const dropdownMenuItemToggle = (show = true) => {
      dropdownMenuInfo.date.show = show;
    };
    const dropdownMenuItemRef: any = ref(null);
    const daterangePickerConfirm = () => {
      dropdownMenuItemRef.value.toggle(false);
      getApprovalMyRelation();
    };

    const daterangePickerClear = () => {
      dropdownMenuInfo.date.value = [];
      dropdownMenuItemRef.value.toggle(false);
      getApprovalMyRelation();
    };
    const onConfirm = () => {
      emit("confirm", allChecked.value);
      popupShow.value = false;
    };
    return {
      popupShow,
      searchValue,
      onSearch,
      list,
      onConfirm,
      onOpen,
      dropdownMenuInfo,
      dropdownMenuItemValueChange,
      dropdownMenuItemToggle,
      formatDropdownMenuDateValue,
      daterangePickerConfirm,
      daterangePickerClear,
      dropdownMenuItemRef,
      onToggle
    };
  }
});
