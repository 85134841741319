
import { computed, defineComponent, PropType, toRefs, watch, reactive } from "vue";
import moment from "moment";

export default defineComponent({
  name: "UfDaterangePicker",
  props: {
    require: {
      type: String
    },
    type: {
      type: String,
      default: "date"
    },
    options: {
      type: Object as PropType<Approval.Dateslot>,
      default: () => ({})
    },
    show: Boolean,
    modelValue: {
      type: Array as PropType<Date[]>,
      required: true
    }
  },
  emits: ["update:show", "update:modelValue", "clear", "confirm"],
  setup(props, { emit }) {
    const { show, modelValue, type, require, options } = toRefs(props);
    const format = type.value == "date" ? "YYYY.MM.DD" : "YYYY-MM-DD HH:mm";
    const data = reactive({
      popupShow: computed({
        get() {
          return show.value;
        },
        set(value) {
          emit("update:show", value);
        }
      }),
      currentPicker: "start",
      start: new Date(),
      end: new Date(),
      minDate: moment("1970-01-01").toDate(),
      maxDate: moment("2070-01-01").toDate()
    });
    const startDateMaxDate = computed(() => {
      return require?.value === "date_insurance" ? moment("2070-01-01").toDate() : data.end;
    });
    watch(
      () => modelValue.value,
      now => {
        switch (require?.value) {
          case "date_insurance": {
            const spaceNum = options.value.space_num || 24;
            data.start = now[0]
              ? new Date(now[0])
              : moment()
                  .add("3", "hours")
                  .toDate();

            data.end = now[1]
              ? new Date(now[1])
              : moment(data.start)
                  .add(spaceNum, "hours")
                  .toDate();
            data.minDate = moment(data.start).toDate();
            break;
          }
          case "business_date":
            data.start = now[0] ? new Date(now[0]) : new Date();
            data.end = now[1]
              ? new Date(now[1])
              : moment(data.start)
                  .add(1, "years")
                  .toDate();
            break;
          default:
            data.start = now[0] ? new Date(now[0]) : new Date();
            data.end = now[1] ? new Date(now[1]) : new Date();
            break;
        }
      },
      {
        immediate: true
      }
    );
    watch(
      () => data.start,
      now => {
        switch (require?.value) {
          case "date_insurance": {
            const spaceNum = options.value.space_num || 24;
            data.end = moment(now)
              .add(spaceNum, "hours")
              .toDate();
            if (spaceNum > 24) {
              data.end = moment(now)
                .add("24", "hours")
                .toDate();
            } else {
              data.maxDate = data.end;
            }
            break;
          }
          default:
            break;
        }
      },
      {
        immediate: true
      }
    );
    const endMinDate = computed(() => {
      if (require?.value === "date_insurance") {
        return moment(data.start)
          .add(1, "minutes")
          .toDate();
      }
      return data.start;
    });
    const onClose = () => {
      data.popupShow = false;
    };
    const onConfirm = () => {
      emit("update:modelValue", [data.start, data.end]);
      emit("confirm");
      onClose();
    };
    const onClear = () => {
      emit("clear");
      onClose();
    };

    return {
      ...toRefs(data),
      startDate: computed(() => moment(data.start).format(format)),
      endDate: computed(() => moment(data.end).format(format)),
      onConfirm,
      onClear,
      onClose,
      startDateMaxDate,
      endMinDate
    };
  }
});
