import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")
  const _component_uf_form_item = _resolveComponent("uf-form-item")
  const _component_van_area = _resolveComponent("van-area")
  const _component_van_popup = _resolveComponent("van-popup")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_uf_form_item, {
      title: _ctx.options.title,
      required: _ctx.options.is_checked == '1',
      readonly: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, _mergeProps({
          modelValue: _ctx.areaPicker.label,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.areaPicker.label = $event)),
          onClick: _ctx.onClick,
          placeholder: "请选择",
          disabled: _ctx.disabled,
          readonly: "",
          "is-link": !_ctx.disabled
        }, _ctx.$attrs), null, 16, ["modelValue", "onClick", "disabled", "is-link"])
      ]),
      _: 1
    }, 8, ["title", "required", "readonly"]),
    _createVNode(_component_van_popup, {
      show: _ctx.areaPicker.showPicker,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (_ctx.areaPicker.showPicker = $event)),
      position: "bottom",
      "safe-area-inset-bottom": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_area, {
          title: "选择地区",
          "area-list": _ctx.areaList,
          onConfirm: _ctx.onConfirm,
          onCancel: _ctx.onCancel
        }, null, 8, ["area-list", "onConfirm", "onCancel"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}