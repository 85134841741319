import axios, { Canceler, AxiosRequestConfig, AxiosInstance } from "axios";
import qs from "qs";
import Axios from "axios";
import { store } from "@/store";
import { Notify } from "vant";

interface PendingConfig {
  url: string;
  cancel: Canceler;
}

const ignoreNotifyURL = ["/Stores_manage/stores_oc_lists", "/Stores_manage/stores_delay_lists"];

const formatData = (origin: OriginResponseData) => {
  const responseData: ResponseData = {
    state: origin.returncode,
    message: origin.returnmsg,
    data: origin.data
  };
  if (origin.total_number) {
    Object.assign(responseData, {
      pageSize: Number(origin.pagesize),
      total: Number(origin.total_number),
      pageCount: Number(origin.pages),
      current: Number(origin.page)
    });
  }
  if (origin.finish_id) {
    responseData.finishId = origin.finish_id;
  }
  return responseData;
};

class Http {
  openCancel = false;
  config: AxiosRequestConfig;
  pending: PendingConfig[] = [];
  cancelToken = axios.CancelToken;

  removePending(config: AxiosRequestConfig) {
    for (const p in this.pending) {
      const item = Number(p);
      const list = this.pending[p];
      if (list.url === config.url + "&request_type=" + config.method && !config.headers.noCancel) {
        list.cancel("重复的请求");
        this.pending.splice(item, 1);
      }
    }
  }

  constructor(config: AxiosRequestConfig) {
    this.config = config;
  }

  create(openCancel = false) {
    this.openCancel = openCancel;
    const instance = axios.create(this.config);
    this.addInterceptors(instance);
    return instance;
  }

  addInterceptors(instance: AxiosInstance) {
    instance.interceptors.request.use(
      config => {
        if (this.openCancel) {
          this.removePending(config);
          config.cancelToken = new this.cancelToken(c => {
            this.pending.push({
              url: config.url + "&request_type=" + config.method,
              cancel: c
            });
          });
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      res => {
        const { data, config } = res;
        if (this.openCancel) {
          this.removePending(config);
        }
        const { returncode: code, returnmsg: msg } = data;
        const responseData = formatData(data);
        res.data = responseData;
        if (code !== "0") {
          // token 过期了
          if (code === "051") {
            console.log("账号下线提醒");
          }
          // 排除002, 051的错误提示
          if (!["002", "051"].includes(code) && msg !== "验证失败") {
            // error(code, { [code]: msg });
          }

          if (!ignoreNotifyURL.some(url => url.includes(config.url as string))) {
            Notify(msg);
          }

          return Promise.reject(responseData);
        }
        return res;
      },
      error => {
        if (error instanceof Axios.Cancel) {
          return Promise.reject(error.message);
        }
        const { status, statusText } = error.response;
        // console.log(error);
        if (status === 500) {
          //500 错误处理
        }
        // 规范化数据格式
        return Promise.reject({ status, message: statusText });
      }
    );
  }
}

export const api = new Http({
  baseURL: process.env.VUE_APP_HTTPURL,
  paramsSerializer(params) {
    return qs.stringify(params);
  },
  transformRequest: [
    data => {
      switch (true) {
        case data instanceof FormData:
          data.append("version", "400");
          data.append("app_type", "4");
          data.append("token", store.state.token as string);
          break;

        default:
          data = qs.stringify({
            ...data,
            version: 400,
            app_type: 4,
            token: store.state.token
          });
          break;
      }
      return data;
    }
  ],
  timeout: 600 * 1000,
  withCredentials: false
}).create();
