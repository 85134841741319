import { computed, Ref } from "vue";
import moment from "moment";

export function useDate() {
  const daterangePickerDateFormat = (data: Ref<Date[]>) => {
    return computed(() => {
      const start = moment(data.value[0]);
      const end = moment(data.value[1]);
      if (data.value.length === 0) {
        return {
          label: "",
          startTimestamp: "",
          endTimestamp: ""
        };
      }
      return {
        label: `${start.format("YYYY.MM.DD")}-${end.format("YYYY.MM.DD")}`,
        startTimestamp: start.format("X"),
        endTimestamp: start.format("X")
      };
    });
  };
  return {
    daterangePickerDateFormat
  };
}
